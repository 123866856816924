import { ProgressBar } from "@components/atoms/progress"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { EnergyCertificateType } from "@energuide/shared-energy-certificate"
import { CertificateStatusView } from "./certificate-status"

type CertificateBoxProps = {
    title: string
    progress: {
        value: number
        max: number
    }
    projectId: number
    productId: number
    type: EnergyCertificateType
}

/**
 * This component handles all the possible states for a creating / download / buying a certificate.
 * We generally use a pollig mechanism to fetch updates.
 * @param props
 * @returns
 */
export function CertificateBox(props: CertificateBoxProps) {
    const { projectId, productId, progress, title, type } = props

    const percent = progress.max > 0 ? (100 * progress.value) / progress.max : 0

    return (
        <div className="grid gap-4">
            <div className="flex justify-between">
                <div className="grid gap-4">
                    <Heading level="h5" className="text-basic-content">
                        {title}
                    </Heading>
                    <div>
                        <span className="font-heading text-heading2 font-medium text-basic-content">{`${percent.toFixed(0)}%`}</span>
                        <Text variant="small">der Daten aufgenommen</Text>
                    </div>
                </div>
                <CertificateStatusView
                    projectId={projectId}
                    type={type}
                    variant="box"
                    progress={progress}
                    productId={productId}
                    title={title}
                />
            </div>
            <ProgressBar value={progress.value} max={progress.max} />
        </div>
    )
}
