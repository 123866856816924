import React from "react"

export function useDelayedLoading(isLoading: boolean, delayMs: number) {
    const [delayedLoading, setDelayedLoading] = React.useState(false)

    React.useEffect(() => {
        setDelayedLoading(false)
        if (!isLoading) {
            return
        }

        const handler = setTimeout(() => {
            setDelayedLoading(true)
        }, delayMs)

        return () => clearTimeout(handler)
    }, [isLoading, delayMs])

    return delayedLoading
}
