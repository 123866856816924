import { Button } from "@components/atoms/buttons"
import config from "@config"
import type { Location } from "@energuide/shared-project"
import * as Form from "@radix-ui/react-form"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import React from "react"
import { DataModal } from "./data-modal"

type LocationModalProps = {
    open: boolean
    onOpenChange: (open: boolean) => void
    onSubmit: React.FormEventHandler<HTMLFormElement>
    center: Location | null
    onCenterChange: (center: Location) => void
}

export default function LocationModal(props: LocationModalProps) {
    const { center, open, onOpenChange, onSubmit, onCenterChange } = props
    const mapRef = React.useRef<mapboxgl.Map | null>(null)

    const onMapSetup = React.useCallback(
        (container: HTMLDivElement | null) => {
            if (!container) {
                if (mapRef.current) {
                    mapRef.current.remove()
                    mapRef.current = null
                }
                return
            }

            if (!mapRef.current) {
                const { longitude, latitude } = center ?? { longitude: 0, latitude: 0 }
                mapboxgl.accessToken = config.VITE_MAPBOX_ACCESS_TOKEN
                mapRef.current = new mapboxgl.Map({
                    container,
                    style: "mapbox://styles/mapbox/outdoors-v12",
                    center: [longitude, latitude],
                    zoom: 17,
                })

                mapRef.current.on("move", (event) => {
                    const { lng, lat } = event.target.getCenter()
                    onCenterChange({
                        longitude: lng,
                        latitude: lat,
                    })
                })
            }
        },
        [center, onCenterChange]
    )

    return (
        <DataModal
            iconClass="ri-map-pin-2-fill"
            title="Position"
            description="Bitte überprüfe die gefundenen Koordinaten und positioniere die Karte so, dass der Marker in die Mitte des Gebäudes zeigt."
            open={open}
            onOpenChange={onOpenChange}
        >
            <Form.Root className="grid gap-4" onSubmit={onSubmit}>
                <div className="grid grid-cols-1 grid-rows-1 overflow-hidden *:col-start-1 *:row-start-1">
                    <div className="h-96 rounded-xl bg-primary-accent" ref={onMapSetup}></div>
                    <div className="pointer-events-none relative">
                        <div
                            className="absolute left-1/2 top-1/2 h-3 w-3 translate-x-[-50%] translate-y-[-50%] rounded-full bg-secondary outline outline-1
                                outline-offset-8 outline-secondary"
                        ></div>
                    </div>
                </div>
                <Button variant="primary" autoFocus data-testid="accept-location-button">
                    Fertig
                </Button>
            </Form.Root>
        </DataModal>
    )
}
