export function LoadingDots() {
    return (
        <div className="flex min-h-4 items-center gap-[6px]">
            <div
                className="aspect-square h-[6px] animate-highBounce rounded-full"
                style={{ backgroundColor: "currentColor" }}
            ></div>
            <div
                className="aspect-square h-[6px] animate-highBounce rounded-full"
                style={{ backgroundColor: "currentColor", animationDelay: "100ms" }}
            ></div>
            <div
                className="aspect-square h-[6px] animate-highBounce rounded-full"
                style={{ backgroundColor: "currentColor", animationDelay: "200ms" }}
            ></div>
        </div>
    )
}
