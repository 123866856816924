import { useExpireSession } from "@api/order/useExpireSession"
import { Button } from "@components/atoms/buttons"
import { useQuery } from "@hooks/useQuery"
import { useTitlebar } from "@hooks/useTitlebar"
import React from "react"
import { useNavigate } from "react-router"

//example url: http://localhost:5173/payment/error?sessionid=cs_test_a1NT7cFKxNpBzAp9Tz2M8lBMxcTZnhjV3HOoyPsXySRGfMIjo1AvqwiYiy

export function StripeError() {
    useTitlebar({
        title: "Fehler bei der Zahlungsabwicklung",
        mode: "hide",
        showContextMenu: false,
    })

    const navigate = useNavigate()
    const query = useQuery()
    const sessionId = query.get("sessionId")
    const { mutateAsync: expireSession } = useExpireSession(sessionId)

    const goHome = React.useCallback(() => {
        navigate("/authenticated/chat")
    }, [navigate])

    React.useEffect(() => {
        let timer = 0
        void expireSession().then(() => {
            // @ts-expect-error
            timer = setTimeout(() => {
                goHome()
            }, 3000)
        })

        return () => clearTimeout(timer)
    }, [expireSession, goHome])

    return (
        <section className="flex h-full w-full flex-col items-center justify-center gap-8 px-20 py-40 font-text">
            <h1 className="text-wrap text-center text-heading1">Fehler bei der Zahlungsabwicklung 😞</h1>
            <Button variant="primary" className="w-full" onClick={() => goHome()}>
                Zurück zur Anwendung
            </Button>
        </section>
    )
}
