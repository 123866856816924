import { Button } from "@components/atoms/buttons"
import { useTitlebar } from "@hooks/useTitlebar"
import React from "react"
import { useNavigate } from "react-router"

export function StripeSuccess() {
    const navigate = useNavigate()
    useTitlebar({
        title: "Zahlung erfolgreich",
        mode: "hide",
        showContextMenu: false,
    })

    const goHome = React.useCallback(() => {
        navigate("/authenticated/chat")
    }, [navigate])

    React.useEffect(() => {
        const timer = setTimeout(() => {
            goHome()
        }, 3000)

        return () => clearTimeout(timer)
    }, [goHome])

    return (
        <section className="flex h-full w-full flex-col items-center justify-center gap-8 px-20 py-40 font-text">
            <h1 className="text-heading1">Zahlung erfolgreich! 🚀</h1>
            <Button className="w-full" variant="primary" onClick={() => goHome()}>
                Zurück zur Anwendung
            </Button>
        </section>
    )
}
