import { Ref } from "@energuide/shared-common"
import { OrderSchema } from "@energuide/shared-payments"
import { z } from "zod"

export enum CertificateStatus {
    none = "none",
    running = "running",
    hasPreview = "has-preview",
    inReview = "in-review",
    hasFinal = "has-final",
    failed = "failed",
}

export const CertificateStatusSchema = z.nativeEnum(CertificateStatus)

export const VariantStatusSchema = z.object({
    status: CertificateStatusSchema,
    url: z.string().optional(),
})

export type VariantStatus = z.infer<typeof VariantStatusSchema>

export const CertificateStatusResponseSchema = z.object({
    draft: VariantStatusSchema,
    final: VariantStatusSchema,
})

export type CertificateStatusResponse = z.infer<typeof CertificateStatusResponseSchema>

export enum EnergyCertificateType {
    Demand = "demand",
    Consumption = "consumption",
}

export const EnergyCertificateTypeSchema = z.nativeEnum(EnergyCertificateType)
export const EnergyCertificatTypeMap: Record<EnergyCertificateType, string> = {
    demand: "Bedarfsausweis",
    consumption: "Verbrauchsausweis",
}

export enum EnergyCertificateVariant {
    Draft = "draft",
    Final = "final",
}

export const EnergyCertificateVariantSchema = z.nativeEnum(EnergyCertificateVariant)

export const EnergyCertificateComponentSchema = z.object({
    id: z.number(),
    error: z.string().nullable(),
    src: z.string().nullable(),
})

export type IEnergyCertificateComponent = z.infer<typeof EnergyCertificateComponentSchema>

export const EnergyCertificateCollectionSchema = z.object({
    id: z.number(),
    project: Ref(),
    taskId: z.string().nullish(),
    type: EnergyCertificateTypeSchema,
    pdf: z.string().nullish(),
    variant: EnergyCertificateVariantSchema.nullable(),
    userRef: Ref(z.string()),
    workflowId: z.string().nullable(),
    order: OrderSchema.nullish(),
})

export type IEnergyCertificateCollection = z.infer<typeof EnergyCertificateCollectionSchema>

const Counts = z.object({
    total: z.number(),
    answered: z.number(),
})

export const ProgressResponse = z
    .object({
        consumption_certificate: Counts.default({ total: 0, answered: 0 }),
        demand_certificate: Counts.default({ total: 0, answered: 0 }),
    })
    .default({
        consumption_certificate: { total: 0, answered: 0 },
        demand_certificate: { total: 0, answered: 0 },
    })

export type IProgressResponse = z.infer<typeof ProgressResponse>
