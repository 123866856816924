import { useForwardSearch } from "@api/geolocation/useForwardSearch"
import { Spinner } from "@components/atoms/buttons/spinner"
import { ChatInputSelect } from "@components/molecules/chat-input-select"
import type { Address, Location } from "@energuide/shared-project"
import { useDebouncedValue } from "@hooks/useDebounce"
import { useDelayedLoading } from "@hooks/useDelayedLoading"
import "mapbox-gl/dist/mapbox-gl.css"
import React, { FormEventHandler, Suspense } from "react"
import TextareaAutosize from "react-textarea-autosize"
import LocationModal from "../location-modal"
import { ChatInputBase, SendButton } from "./chat-input-base"

type LocationIconProps = {
    loading?: boolean
}

function LocationIcon(props: LocationIconProps) {
    if (props.loading) {
        return (
            <div className="grid h-6 w-6 place-items-center text-basic-content-light">
                <Spinner />
            </div>
        )
    }

    return <i className="ri-map-pin-line text-[1.5rem] text-basic-content-light"></i>
}

type ChatInputLocationProps = {
    message: string
    onMessageChanged: (message: string) => void
    onAddressChanged: (message: Address | null) => void
    onLocationChanged: (address: Location | null) => void

    fetchDebounceMs?: number
    loadSpinnerDelayMs?: number
}

export const ChatInputLocation = React.forwardRef<HTMLTextAreaElement, ChatInputLocationProps>((props, ref) => {
    const {
        message,
        onMessageChanged,
        onAddressChanged,
        onLocationChanged,
        fetchDebounceMs = 300,
        loadSpinnerDelayMs = 300,
    } = props

    const [highlighted, setHighlighted] = React.useState<string>("")
    const [selected, setSelected] = React.useState<string>("")
    const [modalOpen, setModalOpen] = React.useState<boolean>(false)
    const [initialMapLocation, setInitialMapLocation] = React.useState<Location | null>(null)

    // debounce search input to prevent excessive API calls
    const debouncedSearch = useDebouncedValue(message, fetchDebounceMs)
    const { data: addresses, isFetching } = useForwardSearch(debouncedSearch, { enabled: !selected })

    // don't show a loading spinner for fast connections.
    // the spinner should only appear after a certain delay
    const isLoading = useDelayedLoading(isFetching, loadSpinnerDelayMs)

    // derive options for our select element from the address response
    const selectOptions = React.useMemo(() => {
        return (
            addresses?.map((address) => ({
                label: address.properties.full_address,
                value: address.id,
            })) || []
        )
    }, [addresses])

    // reset highlighted entry when the list of addresses changes
    React.useEffect(() => {
        setHighlighted("")
    }, [addresses])

    // define keyboard navigation behaviour
    const onChangeHighlighted = (direction: "up" | "down") => {
        if (!highlighted && direction === "up") {
            setHighlighted(selectOptions[0].value)
        } else if (!highlighted && direction === "down") {
            setHighlighted(selectOptions[selectOptions.length - 1].value)
        } else if (direction === "up") {
            const curIdx = selectOptions.findIndex((option) => option.value === highlighted)
            const newIdx = curIdx + 1
            setHighlighted(selectOptions[newIdx % selectOptions.length].value)
        } else if (direction === "down") {
            const curIdx = selectOptions.findIndex((option) => option.value === highlighted)
            const newIdx = curIdx - 1
            setHighlighted(selectOptions[newIdx < 0 ? selectOptions.length - 1 : newIdx].value)
        }
    }

    // handle selection of an address from the list
    // the result will be held in state until the user confirms
    // the address candidate by hitting the "send" button (or ENTER key)
    const onSelect = (addressId: string | null) => {
        if (!addressId) {
            return
        }

        setSelected(addressId)
        setHighlighted("")

        // load the selected data into the search bar and store the addresses center coordinates
        const address = addresses?.find((address) => address.id === addressId)
        if (address) {
            const [lng, lat] = address.geometry.coordinates
            const { context } = address.properties

            onMessageChanged(address.properties.full_address)

            onLocationChanged({
                longitude: lng,
                latitude: lat,
            })

            setInitialMapLocation({
                longitude: lng,
                latitude: lat,
            })

            onAddressChanged({
                street_name: context.address?.street_name ?? "",
                house_number: context.address?.address_number ?? "",
                city: context.place?.name ?? "",
                postal_code: context.postcode?.name ?? "",
                federal_state: context.region?.name ?? "",
                country: context.country?.country_code ?? "",
            })
        }
    }

    // open the position modal, if we have a selected address candidate
    // the modal can be used to confirm the exact coordinates
    const onTryAccept = () => {
        if (selected) {
            setModalOpen(true)
            return true
        }

        return false
    }

    const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
        switch (event.key) {
            case "ArrowDown":
                event.preventDefault()
                onChangeHighlighted("down")
                break
            case "ArrowUp":
                event.preventDefault()
                onChangeHighlighted("up")
                break
            case "Enter":
                event.preventDefault()
                if (!onTryAccept()) {
                    onSelect(highlighted)
                }

                break
        }
    }

    // whenever the user types into the search box:
    // - clear any previously selected addess
    // - search for new results
    const onInput: FormEventHandler<HTMLTextAreaElement> = (event) => {
        setSelected("")
        onMessageChanged(event.currentTarget.value)
    }

    // reset inputs on message submit
    const onSubmit = () => {
        setModalOpen(false)
        onMessageChanged("")
    }

    return (
        <ChatInputBase
            header={
                <ChatInputSelect
                    options={selectOptions}
                    highlighted={highlighted}
                    onHover={setHighlighted}
                    onSelect={onSelect}
                    disabled={!!selected}
                />
            }
            tools={<LocationIcon loading={isLoading} />}
            mainInput={
                <TextareaAutosize
                    autoCapitalize="off"
                    autoComplete="off"
                    autoCorrect="off"
                    maxRows={1}
                    onKeyDown={onKeyDown}
                    onInput={onInput}
                    value={message}
                    placeholder={"Musterstr 6, 01099 Dresden"}
                    className="placeholder-basic-content-light/50"
                    ref={ref}
                    data-testid="chat-input"
                    id="chat-input"
                    autoFocus
                />
            }
            sendButton={<SendButton type="button" disabled={!selected} onClick={onTryAccept} />}
        >
            <Suspense fallback={null}>
                <LocationModal
                    open={modalOpen}
                    onOpenChange={setModalOpen}
                    onSubmit={onSubmit}
                    center={initialMapLocation}
                    onCenterChange={onLocationChanged}
                />
            </Suspense>
        </ChatInputBase>
    )
})
