import { usePaidOrders } from "@api/order/usePaidOrders"
import { useUser } from "@api/user/useUser"
import { Button, LinkButton } from "@components/atoms/buttons"
import { MenuIcon } from "@components/atoms/icons"
import { Heading, Text } from "@components/atoms/typography"
import { UserAvatar } from "@components/molecules/avatar"
import SwitchThemeButton from "@components/molecules/switch-theme"
import { RoleTypes } from "@energuide/shared-user"
import { useMobile } from "@hooks/useMobile"
import * as Dialog from "@radix-ui/react-dialog"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"
import { cls } from "@utils"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { Link, useLocation } from "react-router-dom"

type ISideMenu = {
    className?: string
}

function SideMenu(props: ISideMenu) {
    const { className } = props
    const location = useLocation()
    const { data: user } = useUser()
    const { displayName = "", email = "", avatar, role } = user ?? {}
    const { url = "" } = avatar ?? {}
    const isAdmin = role?.type === RoleTypes.ADMIN

    const { data: orders } = usePaidOrders({ enabled: isAdmin })

    return (
        <motion.div
            className={cls("grid h-dvh grid-rows-[minmax(0,1fr)_auto_auto] bg-basic-strong", className)}
            initial={{ x: "-100%" }}
            animate={{ x: "0%" }}
            exit={{ x: "-100%" }}
            transition={{
                ease: "circInOut",
            }}
            data-testid="menu-container"
        >
            <nav className="grid h-full grid-rows-[auto_minmax(0,1fr)] gap-4 overflow-hidden px-6 py-8">
                <div
                    className="grid grid-cols-[minmax(0,1fr)] content-start gap-1 overflow-auto break-words"
                    data-testid="project-list"
                >
                    <Link
                        to={`/authenticated/chat`}
                        className={cls("text-text flex items-center gap-4 rounded-md px-4 py-2", {
                            "bg-basic-accent": location.pathname.includes("/authenticated/chat"),
                        })}
                    >
                        <i className="ri-chat-3-fill text-[1.5rem] leading-none"></i>
                        <Heading level="h5">EnerGuide</Heading>
                    </Link>
                    <Link
                        to={`/authenticated/properties`}
                        className={cls("text-text flex items-center gap-4 rounded-md px-4 py-2", {
                            "bg-basic-accent": location.pathname.includes("/authenticated/properties"),
                        })}
                    >
                        <i className="ri-home-fill text-[1.5rem] leading-none"></i>
                        <Heading level="h5">Liegenschaften</Heading>
                    </Link>
                    {/* TODO: Add documents page */}
                    {/* 
                    <Link
                        to={`/authenticated/documents`}
                        className={cls("flex items-center gap-4 rounded-md px-4 py-2 text-text", {
                            "bg-primaryAccent2/30": location.pathname.includes("/authenticated/documents"),
                        })}
                    >
                        <i className="ri-file-list-2-fill text-[1.5rem] leading-none"></i>
                        <Text variant="strong2" className="text-text">
                            Dokumente
                        </Text>
                    </Link> */}
                </div>
            </nav>
            <div className="grid grid-cols-[minmax(0,1fr)] gap-4 border-t border-solid border-basic-accent p-6">
                {/* <div className="border-b-1 grid grid-cols-[minmax(0,1fr)] gap-4 border-solid border-y-basic-accent p-6"> */}
                <div className="relative grid gap-4">
                    <UserAvatar
                        src={url ?? ""}
                        alt=""
                        initials={displayName?.[0] ?? ""}
                        variant="menu"
                        className="size-12 outline outline-1 outline-offset-[-1px] outline-white/10" // outline-black-white/10"
                    />
                    <div className="flex flex-col gap-4">
                        <div className="overflow-hidden">
                            <Text variant="strong2" className="text-text text-bodyStrong">
                                {/* text-basic-content */}
                                {displayName} {isAdmin && "(Admin)"}
                            </Text>
                            <Text
                                variant="small"
                                className="w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-basic-content-light"
                            >
                                {email}
                            </Text>
                        </div>
                        <div className="flex gap-4">
                            <LinkButton
                                href="/authenticated/account"
                                variant="tertiary"
                                className="text-textLight w-full justify-start px-0 font-heading text-heading5" //"border-1 border-text/20 aspect-square rounded-full border p-2 text-basic-content/40
                                dataTestId="menu-button"
                            >
                                <i className="ri-settings-fill text-[1.5rem] leading-none" />
                                Einstellungen
                            </LinkButton>
                            <SwitchThemeButton />
                        </div>
                    </div>
                </div>
                {isAdmin && (
                    <>
                        {orders && isAdmin && (
                            <LinkButton variant="secondary" href="/authenticated/reviews">
                                Reviews ({orders?.length})
                            </LinkButton>
                        )}
                        <LinkButton variant="secondary" href="/authenticated/project-debug">
                            Debug
                        </LinkButton>
                        <LinkButton variant="tertiary" href="/authenticated/components">
                            Components
                        </LinkButton>
                    </>
                )}
            </div>
            <div className="grid grid-cols-[minmax(0,1fr)] gap-4 border-t border-solid border-basic-accent p-6">
                <footer className="flex gap-4">
                    <LinkButton
                        href="https://enerithm.com/terms-and-conditions"
                        className="text-textLight text-small"
                        variant="freeform"
                        isExternal={true}
                    >
                        AGB
                    </LinkButton>
                    <LinkButton
                        href="https://enerithm.com/data-protection"
                        className="text-textLight text-small"
                        isExternal={true}
                        variant="freeform"
                    >
                        Datenschutz
                    </LinkButton>
                    <LinkButton
                        href="/licenses"
                        className="text-textLight text-small"
                        isExternal={false}
                        variant="freeform"
                    >
                        Lizenzen
                    </LinkButton>

                    {/* <p className="overflow-hidden text-ellipsis text-tiny text-basic-content-light">{commitHash}</p> */}
                </footer>
            </div>
        </motion.div>
    )
}

type IMenu = {
    mode: "mobile" | "desktop"
}

export function Menu(props: IMenu) {
    const { mode } = props
    const isMobileMode = mode === "mobile"
    const isMobile = useMobile()
    const [open, setOpen] = React.useState<boolean>(true)
    const location = useLocation()

    React.useEffect(() => {
        setOpen(false)
    }, [location.pathname])

    if (isMobileMode && !isMobile) {
        return <div className="w-10"></div>
    }

    if (!isMobileMode) {
        return <SideMenu />
    }

    return (
        <Dialog.Root onOpenChange={setOpen} open={open} modal>
            <Dialog.Trigger asChild>
                <Button variant="tertiary" className="text-strong2 text-basic-content" data-testid="menu-button">
                    <MenuIcon />
                </Button>
            </Dialog.Trigger>
            <AnimatePresence>
                {open && (
                    <Dialog.Portal forceMount>
                        <Dialog.DialogOverlay
                            className="fixed bottom-0 left-0 right-0 top-0"
                            onClick={() => setOpen(false)}
                        >
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{
                                    ease: "circInOut",
                                }}
                                className="h-full w-full bg-basic/50 backdrop-blur-sm"
                            ></motion.div>
                        </Dialog.DialogOverlay>

                        <Dialog.Content forceMount>
                            <VisuallyHidden.Root asChild>
                                <Dialog.Title>Menü</Dialog.Title>
                            </VisuallyHidden.Root>
                            <VisuallyHidden.Root asChild>
                                <Dialog.Description>Hauptmenü der Anwendung</Dialog.Description>
                            </VisuallyHidden.Root>
                            <SideMenu className="fixed bottom-0 top-0 z-20" />
                        </Dialog.Content>
                    </Dialog.Portal>
                )}
            </AnimatePresence>
        </Dialog.Root>
    )
}
