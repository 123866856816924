import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import {
    CertificateStatus,
    CertificateStatusResponseSchema,
    EnergyCertificateType,
} from "@energuide/shared-energy-certificate"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

type IUseCertificate = {
    enabled?: boolean
}

export function useCertificateStatus(projectId: number | null, type: EnergyCertificateType, params?: IUseCertificate) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["certificate-status", projectId, type],
        queryFn: async () => {
            const response = await handleFetch(async () =>
                fetch(`${apiUrl}/energy-certificate/status?projectId=${projectId}&type=${type}`, {
                    headers: {
                        ...authHeader,
                    },
                })
            )

            if (!response) {
                return null
            }

            const parsed = handleParse(() => CertificateStatusResponseSchema.parse(response))
            return parsed
        },
        refetchInterval: (query) => {
            const { data } = query.state
            const { draft, final } = data ?? {}
            const shouldRefetch =
                draft?.status === CertificateStatus.running || final?.status === CertificateStatus.running
            console.log("should refetch", shouldRefetch)
            return shouldRefetch ? 2000 : false
        },
        enabled: !!enabled && !!authHeader && !!projectId,
    })
}
