import React from "react"

export function useDebouncedValue<T>(value: T, delayMs: number) {
    const [debouncedValue, setDebouncedValue] = React.useState<T>(value)

    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delayMs)
        return () => clearTimeout(handler)
    }, [value, delayMs])

    return debouncedValue
}
