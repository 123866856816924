import { ILogin, login } from "@api/auth/login.ts"
import { Button } from "@components/atoms/buttons"
import { EnerGuide } from "@components/atoms/energuide"
import { Input } from "@components/atoms/input.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { Footer } from "@layouts/auth.tsx"
import * as Form from "@radix-ui/react-form"
import { motion } from "framer-motion"
import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "sonner"

export function Login() {
    const form = React.useRef<HTMLFormElement | null>(null)
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState<boolean>(false)

    useTitlebar({
        title: "",
        mode: "back",
        backTo: "/auth/login-selection",
        showContextMenu: false,
    })

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()

        if (!form.current) {
            return
        }

        try {
            setLoading(true)

            const formData = new FormData(form.current)
            const loginData = Object.fromEntries(formData) as ILogin

            const user = await login(loginData)
            form.current?.reset()

            //This happens inside App.tsx as well. Potential for refactoring here
            if (!user?.displayName) {
                navigate("/auth/register-additions")
            } else {
                navigate("/authenticated/chat")
            }
        } catch (error: any) {
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <EnerGuide
                glow
                className="absolute inset-0 z-[-1] grid overflow-hidden"
                energuideClassName="absolute top-[-12%] lg:top-[-22%] z-[-1] aspect-square w-[18rem] sm:w-[20rem] md:w-[30rem] lg:w-[22rem] xl:w-[22rem] 2xl:w-[40rem] justify-self-center"
                layoutId="energuide"
            />

            <div className="mx-auto grid min-h-full w-full max-w-screen-sm content-end lg:content-center">
                <div className="grid gap-4 rounded-lg bg-basic-strong px-8 pt-8 shadow-lg backdrop-blur-2xl">
                    <motion.div className="grid gap-2" layoutId="login-title">
                        <Heading level="h1">Anmelden</Heading>
                        <Text variant="body1" className="text-basic-content-medium">
                            Nur noch kurz anmelden, dann kann es direkt losgehen.
                        </Text>
                    </motion.div>

                    <Form.Root onSubmit={onSubmit} ref={form} className="grid gap-8">
                        <motion.div
                            transition={{
                                type: "tween",
                                ease: "circInOut",
                            }}
                            style={{ originX: 0.5, originY: 1 }}
                            initial={{ scaleY: 0.75, transform: "translateY(25%)", opacity: 0 }}
                            animate={{ scaleY: 1, transform: "translateY(0%)", opacity: 1 }}
                            className="grid gap-6"
                        >
                            <Input
                                name="identifier"
                                label="E-Mail"
                                type="email"
                                required
                                messages={[
                                    {
                                        content: "E-Mail Adresse benötigt",
                                        match: "valueMissing",
                                    },
                                    {
                                        content: "E-Mail Adresse ungültig",
                                        match: "typeMismatch",
                                    },
                                ]}
                            />
                            <div className="grid gap-2.5">
                                <Input
                                    name="password"
                                    label="Passwort"
                                    type="password"
                                    required
                                    minLength={6}
                                    messages={[
                                        {
                                            content: "Mindestens 6 Zeichen benötigt",
                                            match: "tooShort",
                                        },
                                        {
                                            content: "Passwort benötigt",
                                            match: "valueMissing",
                                        },
                                    ]}
                                />
                                <Link
                                    to="/auth/forgot-password"
                                    className="justify-self-end text-black-white underline"
                                >
                                    Passwort vergessen?
                                </Link>
                            </div>
                        </motion.div>

                        <Form.Submit asChild>
                            <Button variant="primary" loading={loading}>
                                Login
                            </Button>
                        </Form.Submit>
                    </Form.Root>

                    <Footer>
                        <Text variant="small" className="text-basic-content-medium">
                            Du hast noch keinen Account?
                        </Text>
                        <Link to="/auth/register-selection" className="text-black-white underline">
                            Registrieren
                        </Link>
                    </Footer>
                </div>
            </div>
        </>
    )
}
