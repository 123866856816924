import { Button } from "@components/atoms/buttons"
import { useEffect, useState } from "react"

const SwitchThemeButton = () => {
    const [isDark, setIsDark] = useState(false)

    useEffect(() => {
        // Check current theme status
        const updateThemeState = () => {
            const hasDarkClass = document.documentElement.classList.contains("dark")
            setIsDark(hasDarkClass)
        }

        // Set initial state
        updateThemeState()

        // Listen for theme changes to keep button state in sync
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "attributes" && mutation.attributeName === "class") {
                    updateThemeState()
                }
            })
        })

        observer.observe(document.documentElement, { attributes: true })
        return () => observer.disconnect()
    }, [])

    const toggleTheme = () => {
        const newIsDark = !isDark

        // Remove both classes first
        document.documentElement.classList.remove("dark", "light")
        // Add new class
        document.documentElement.classList.add(newIsDark ? "dark" : "light")
        // Save preference
        localStorage.setItem("theme", newIsDark ? "dark" : "light")
    }

    return (
        <Button
            variant="freeform"
            onClick={toggleTheme}
            data-testid="switch-theme-button"
            title={`Switch to ${isDark ? "light" : "dark"} mode`}
            className="px-4"
        >
            <i className={`${isDark ? "ri-sun-fill" : "ri-moon-fill"} text-[1.2rem]`}></i>
        </Button>
    )
}

export default SwitchThemeButton
