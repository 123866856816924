import { motion, AnimatePresence } from "framer-motion"
import { useOverlay } from "../../../hooks/useOverlay"

type IOverlay = React.PropsWithChildren<{
    isOpen: boolean
    className?: string
}>

function Overlay({ isOpen, className, children }: IOverlay) {
    const { close } = useOverlay()

    const maybeOnDismiss = (e: React.MouseEvent<HTMLDivElement> | undefined) => {
        if (e && e.target === e.currentTarget) {
            close()
        }
    }

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    key="overlay"
                    className={`absolute z-50 flex h-screen w-screen bg-basic/80 ${className}`}
                    onClick={maybeOnDismiss}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    )
}
export default Overlay
