import { IProjectRole, IProjectRoles } from "@api/project-roles/schema.ts"
import { Button } from "@components/atoms/buttons"
import { Input } from "@components/atoms/input.tsx"
import { Text } from "@components/atoms/typography"
import { UserAvatar } from "@components/molecules/avatar.tsx"
import { Select } from "@components/molecules/select.tsx"
import { DataModal } from "@components/organisms/data-modal.tsx"
import * as Form from "@radix-ui/react-form"
import React from "react"
import { toast } from "sonner"

type Member = {
    avatarSrc: string
    name: string
    role: IProjectRole
}

type ITeamMembers = {
    members: Member[]
    onAdd: (data: FormData) => Promise<void>
    projectRoles: IProjectRoles
}

export function TeamMembers(props: ITeamMembers) {
    const { members, onAdd, projectRoles } = props
    const [open, setOpen] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()

        try {
            setLoading(true)
            await onAdd(new FormData(event.currentTarget))
            setOpen(false)
        } catch (error: any) {
            console.error(error)
            if (error.message) {
                toast.error(error?.message)
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="grid gap-4">
            <header className="grid grid-cols-2 gap-8">
                <Text variant="strong2" className="text-basic-content">
                    Benutzer
                </Text>
                <Text variant="strong2" className="text-basic-content justify-self-end">
                    Rolle
                </Text>
            </header>
            <div className="grid gap-3">
                {members.map(({ avatarSrc, name, role }, idx) => (
                    <div className="grid grid-cols-[auto_1fr_auto] items-center gap-6" key={idx}>
                        <UserAvatar src={avatarSrc} alt="" variant="mini" initials={name[0]} />
                        <Text variant="strong2" className="text-basic-content">
                            {name}
                        </Text>
                        <Text variant="body2" className="justify-self-end text-primary">
                            {role.role}
                        </Text>
                    </div>
                ))}
                <DataModal
                    iconClass="ri-add-line"
                    title="Teammitglied hinzufügen"
                    description="Gib die E-Mail Adresse der Person an, die du zum Projekt hinzufügen möchtest und wähle die gewünschte Rolle aus."
                    open={open}
                    onOpenChange={setOpen}
                    trigger={
                        <Button variant="freeform" className="grid grid-cols-[auto_1fr] justify-items-start gap-6">
                            <i
                                className={
                                    "ri-add-line text-basic-content-light grid w-8 place-items-center text-[1.5rem] leading-none"
                                }
                            ></i>
                            <Text variant="strong2" className=" text-basic-content-light">
                                Hinzufügen
                            </Text>
                        </Button>
                    }
                >
                    <Form.Root className="grid gap-6" onSubmit={onSubmit}>
                        <Input name="email" type="email" label="E-Mail" />
                        <Select
                            name="role"
                            label="Rolle"
                            id="role-select"
                            defaultValue={`${projectRoles?.[0]?.id ?? ""}`}
                            options={
                                projectRoles?.map((role) => ({
                                    value: `${role.id}`,
                                    label: role.role,
                                })) ?? []
                            }
                            required
                        />
                        <Button variant="primary" loading={loading}>
                            Hinzufügen
                        </Button>
                    </Form.Root>
                </DataModal>
            </div>
        </div>
    )
}
