import { Text } from "@components/atoms/typography"
import * as RadixSwitch from "@radix-ui/react-switch"
import { cls } from "@utils"

type ISwitch = {
    description?: string
    label: string
} & RadixSwitch.SwitchProps

export function Switch(props: ISwitch) {
    const { description, label, className, ...switchProps } = props
    return (
        <div className="grid grid-cols-[auto_auto] justify-between gap-1">
            {description && (
                <Text variant="small" className="text-basic-content-light col-span-2">
                    {description}
                </Text>
            )}
            <label className="text-basic-content text-strong2">{label}</label>
            <RadixSwitch.Root
                {...switchProps}
                className={cls("bg-basic h-6 w-12 rounded-full p-1 outline outline-1 outline-primary", className)}
            >
                <RadixSwitch.Thumb
                    className={cls(
                        `block aspect-square h-full rounded-full outline outline-1 outline-offset-[-1px] outline-primary transition-all
                        data-[state=checked]:translate-x-6 data-[state=checked]:bg-primary`
                    )}
                />
            </RadixSwitch.Root>
        </div>
    )
}
