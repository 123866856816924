import { Heading, Text } from "@components/atoms/typography"

const Colors = () => {
    return (
        <section className="border-gray-200 flex flex-col gap-10 border-b pb-8">
            <Heading level="h2">Color Palette</Heading>

            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                {/* Primary and Secondary Colors */}
                <div className="rounded-lg bg-basic p-4">
                    <Heading level="h3" className="mb-4">
                        Primary Colors
                    </Heading>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-primary"></div>
                            <Text variant="small">primary</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-primary-disabled"></div>
                            <Text variant="small">primary-disabled</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-primary-accent"></div>
                            <Text variant="small">primary-accent</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-primary-content"></div>
                            <Text variant="small" className="text-basic-content">
                                primary-content
                            </Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-primary-disabled"></div>
                            <Text variant="small">primary-disabled</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-primary-content-disabled"></div>
                            <Text variant="small" className="text-basic-content">
                                primary-content-disabled
                            </Text>
                        </div>
                    </div>
                </div>

                <div className="rounded-lg bg-basic p-4">
                    <Heading level="h3" className="mb-4">
                        Secondary Colors
                    </Heading>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-secondary"></div>
                            <Text variant="small">secondary</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-secondary-disabled"></div>
                            <Text variant="small">secondary-disabled</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-secondary-accent"></div>
                            <Text variant="small">secondary-accent</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-secondary-content"></div>
                            <Text variant="small" className="text-basic-content">
                                secondary-content
                            </Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-secondary-disabled"></div>
                            <Text variant="small">secondary-disabled</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-secondary-content-disabled"></div>
                            <Text variant="small" className="text-basic-content">
                                secondary-content-disabled
                            </Text>
                        </div>
                    </div>
                </div>

                {/* Basic and Status Colors */}
                <div className="rounded-lg bg-basic p-4">
                    <Heading level="h3" className="mb-4">
                        Basic Colors
                    </Heading>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md border border-basic-accent bg-basic"></div>
                            <Text variant="small">basic</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-basic-accent "></div>
                            <Text variant="small">basic-accent</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-basic-strong"></div>
                            <Text variant="small">basic-strong</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-basic-content"></div>
                            <Text variant="small">basic-content</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-basic-content-medium"></div>
                            <Text variant="small">basic-content-medium</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-basic-content-light"></div>
                            <Text variant="small">basic-content-light</Text>
                        </div>
                    </div>
                </div>

                <div className="rounded-lg bg-basic p-4">
                    <Heading level="h3" className="mb-4">
                        Status Colors
                    </Heading>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-warning"></div>
                            <Text variant="small">warning</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-success"></div>
                            <Text variant="small">success</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-error"></div>
                            <Text variant="small">error</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-warning-content"></div>
                            <Text variant="small">warning-content</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-success-content"></div>
                            <Text variant="small">success-content</Text>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="h-20 w-full rounded-md bg-error-content"></div>
                            <Text variant="small">error-content</Text>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Colors
