import { flexRender, Row, Table } from "@tanstack/react-table"

type IDesktopView<TData> = {
    table: Table<TData>
    rowSelection: boolean
    onRowClick?: (row: Row<TData>) => void
}

export function DesktopView<TData>(props: IDesktopView<TData>) {
    const { table, rowSelection, onRowClick } = props
    return (
        <div className="hidden md:block">
            <table className="w-full">
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id} className="group min-h-12">
                            {rowSelection && (
                                <td className="w-8">
                                    <input
                                        type="checkbox"
                                        checked={table.getIsAllRowsSelected()}
                                        onChange={table.getToggleAllRowsSelectedHandler()}
                                        onClick={(e) => e.stopPropagation()}
                                        className="border-textVeryLight accent-textVeryLight checked:accent-textLight size-3 cursor-pointer appearance-none rounded-sm
                                            border p-1.5 opacity-0 checked:appearance-auto checked:opacity-100 group-hover:table-cell"
                                    />
                                </td>
                            )}
                            {headerGroup.headers.map((header) => (
                                <th key={header.id} className="group border-b border-border-light py-3 text-left">
                                    <div
                                        className={`text-textStrong flex select-none items-center gap-1 text-bodyStrong ${header.column.getCanSort() && "cursor-pointer"}`}
                                        onClick={() => header.column.getCanSort() && header.column.toggleSorting()}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(header.column.columnDef.header, header.getContext())}
                                        {header.column.getCanSort() &&
                                            (header.column.getIsSorted() === "asc" ? (
                                                <i className="ri-arrow-up-line" />
                                            ) : header.column.getIsSorted() === "desc" ? (
                                                <i className="ri-arrow-down-line" />
                                            ) : (
                                                <i className="ri-arrow-up-down-line" />
                                            ))}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr
                            key={row.id}
                            className="group min-h-12 cursor-pointer"
                            onClick={() => onRowClick?.(row)}
                            data-testid="table-row"
                        >
                            {rowSelection && (
                                <td className="w-8">
                                    <input
                                        type="checkbox"
                                        data-testid={`row-checkbox-${row.id}`}
                                        checked={row.getIsSelected()}
                                        onChange={row.getToggleSelectedHandler()}
                                        onClick={(e) => e.stopPropagation()}
                                        className="accent-textVeryLight checked:accent-textLight size-3 cursor-pointer appearance-none rounded-sm border
                                            border-basic-content-light p-1.5 checked:appearance-auto md:opacity-0 md:checked:opacity-100 md:group-hover:table-cell"
                                    />
                                </td>
                            )}
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id} className="border-b border-border-light py-3 align-top">
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
