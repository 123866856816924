// Reference: Shadcn UI Sheet component - https://ui.shadcn.com/docs/components/sheet
import { Image } from "@components/atoms/image"
import { Scrollable } from "@components/molecules/scrollable"
import * as SheetPrimitive from "@radix-ui/react-dialog"
import { cls } from "@utils"
import { cva, type VariantProps } from "class-variance-authority"
import { motion } from "framer-motion"
import * as React from "react"

const Sheet = SheetPrimitive.Root

const SheetTrigger = SheetPrimitive.Trigger

const SheetClose = SheetPrimitive.Close

const SheetPortal = SheetPrimitive.Portal

const SheetOverlay = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Overlay>,
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
    <SheetPrimitive.Overlay className={cls("fixed bottom-0 left-0 right-0 top-0", className)} {...props} ref={ref}>
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                ease: "circInOut",
            }}
            className="h-full w-full bg-basic/50 backdrop-blur-sm"
        ></motion.div>
    </SheetPrimitive.Overlay>
))
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName

const sheetVariants = cva(
    "fixed z-50 gap-4 bg-basic shadow-lg transition ease-in-out data-[state=closed]:duration-300 data-[state=open]:duration-500 data-[state=open]:animate-in data-[state=closed]:animate-out",
    {
        variants: {
            side: {
                top: "inset-x-0 top-0 data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
                bottom: "inset-x-0 bottom-0 data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
                left: "inset-y-0 left-0 h-full w-3/4 data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-2xl",
                right: "inset-y-0 right-0 h-full w-3/4 data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-2xl",
            },
        },
        defaultVariants: {
            side: "right",
        },
    }
)

interface SheetContentProps
    extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
        VariantProps<typeof sheetVariants> {
    title: string
    description: string
    image: string
}

const SheetContent = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Content>, SheetContentProps>(
    ({ side = "right", title, description, image, className, children, ...props }, ref) => (
        <SheetPortal>
            <SheetOverlay />
            <SheetPrimitive.Content ref={ref} className={cls(sheetVariants({ side }), className)} {...props}>
                <Scrollable className="h-full">
                    <SheetHeader className="border-b border-basic-accent p-6">
                        <div className="flex flex-col justify-between">
                            <SheetPrimitive.Close
                                className="ring-offset-background flex size-6 items-center justify-center rounded-md border border-basic-content-light
                                    bg-basic-content-light/5 opacity-70 transition-opacity hover:opacity-100 disabled:pointer-events-none
                                    data-[state=open]:bg-secondary"
                            >
                                <i className="ri-close-large-line text-basic-content-light" />
                                <span className="sr-only">Close</span>
                            </SheetPrimitive.Close>
                            <div>
                                <SheetTitle className="font-heading text-heading3">{title}</SheetTitle>
                                <SheetDescription>{description}</SheetDescription>
                            </div>
                        </div>
                        <div className="relative aspect-square size-32 rounded-lg object-cover">
                            <Image
                                src={image}
                                alt=""
                                fallback={<i className="ri-home-3-line text-[3rem] text-basic-content-medium"></i>}
                                className="h-full w-full rounded-lg object-cover"
                            />
                        </div>
                    </SheetHeader>
                    {children}
                </Scrollable>
            </SheetPrimitive.Content>
        </SheetPortal>
    )
)
SheetContent.displayName = SheetPrimitive.Content.displayName

const SheetHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cls("flex justify-between text-center sm:text-left", className)} {...props} />
)
SheetHeader.displayName = "SheetHeader"

const SheetFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cls("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className)} {...props} />
)
SheetFooter.displayName = "SheetFooter"

const SheetTitle = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
    <SheetPrimitive.Title ref={ref} className={cls("text-lg text-foreground font-semibold", className)} {...props} />
))
SheetTitle.displayName = SheetPrimitive.Title.displayName

const SheetDescription = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Description>,
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
    <SheetPrimitive.Description ref={ref} className={cls("text-sm text-muted-foreground", className)} {...props} />
))
SheetDescription.displayName = SheetPrimitive.Description.displayName

export {
    Sheet,
    SheetPortal,
    SheetOverlay,
    SheetTrigger,
    SheetClose,
    SheetContent,
    SheetHeader,
    SheetFooter,
    SheetTitle,
    SheetDescription,
}
