import Markdown from "markdown-to-jsx"

type MarkdownInterpreterProps = {
    content: string
}

export function MarkdownInterpreter(props: MarkdownInterpreterProps) {
    const { content } = props

    return (
        <Markdown
            className="text-basic-content"
            options={{
                overrides: {
                    h1: {
                        props: {
                            className: "font-heading text-heading1",
                        },
                    },
                    h2: {
                        props: {
                            className: "font-heading text-heading2",
                        },
                    },
                    h3: {
                        props: {
                            className: "font-heading text-heading3",
                        },
                    },
                    h4: {
                        props: {
                            className: "font-heading text-heading3",
                        },
                    },
                    a: {
                        props: {
                            className: "text-basic-content-medium underline hover:no-underline",
                        },
                    },
                    ul: {
                        props: {
                            className: "list-disc ml-5 grid gap-2",
                        },
                    },
                    pre: {
                        props: {
                            className: "whitespace-normal",
                        },
                    },
                    code: {
                        props: {
                            className: "whitespace-normal font-text",
                        },
                    },
                },
            }}
        >
            {content ?? ""}
        </Markdown>
    )
}
