import { z } from "zod"

enum ProductId {
    ConsumptionCertificate = 1, // Verbrauchsausweis
    DemandCertificate = 2, //Bedarfsausweis

    Coupon_ConsumptionCertificate = 7,
    Coupon_DemandCertificate = 8,
}

const ProductIdSchema = z.preprocess(
    (value) => (typeof value === "string" ? parseInt(value, 10) : value),
    z.nativeEnum(ProductId)
)

enum ProductType {
    Certificate = "certificate",
    Coupon = "coupon",
}

/**
 * @description Product schema for the frontend
 * @property {number} price - The product price in cents
 */
const ProductSchema = z.object({
    id: z.number(),
    name: z.string(),
    productType: z.enum([ProductType.Certificate, ProductType.Coupon]),
    stripeProductId: z.string().nullish(),
    price: z.number().positive().nullish(),
})

type IProduct = z.infer<typeof ProductSchema>

export { ProductSchema, ProductId, ProductIdSchema, ProductType }
export type { IProduct }
