import { apiUrl, handleFetch } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"

export function useExpireSession(sessionId: string | null) {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async () => {
            if (!authHeader || !sessionId) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/order/expire-checkout`, {
                    method: "PUT",
                    body: JSON.stringify({
                        sessionId,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                })
            )
        },
    })
}
