import { LinkButton } from "@components/atoms/buttons"
import { EnerGuide } from "@components/atoms/energuide"
import { Heading } from "@components/atoms/typography"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { motion } from "framer-motion"

export function RegisterTutorial() {
    useTitlebar({
        title: "",
        mode: "back",
        backTo: "auth/register-additions",
        showContextMenu: false,
    })

    return (
        <>
            <EnerGuide
                glow
                className="absolute inset-0 z-[-1] grid overflow-hidden"
                energuideClassName="absolute top-[-60%] lg:top-[-22%] z-[-1] aspect-square justify-self-center w-[18rem] sm:w-[20rem] md:w-[30rem] lg:w-[22rem] xl:w-[25rem] 2xl:w-[40rem]"
                layoutId="energuide"
            />

            <div className="mx-auto grid min-h-full w-full max-w-screen-md content-center gap-12 pt-2">
                <div className="grid gap-12 rounded-lg bg-basic-strong px-8 py-8 shadow-lg backdrop-blur-2xl">
                    <motion.div className="grid gap-12 px-8" layoutId="register-title">
                        <Heading level="h1" className="text-center">
                            Persönliches Tutorial erwünscht?
                        </Heading>
                        <div className="grid grid-rows-1 gap-4 lg:grid-cols-2 lg:gap-20">
                            <LinkButton
                                variant="primary"
                                href="https://calendly.com/d/cqh6-7cv-ryx/demo-energuide"
                                isExternal={true}
                                dataTestId="book-demo-link"
                            >
                                Termin Buchen
                                <i className="ri-time-fill"></i>
                            </LinkButton>
                            <LinkButton variant="primary" href="/authenticated/chat" dataTestId="no-demo-link">
                                Nicht nötig
                                <i className="ri-arrow-right-fill"></i>
                            </LinkButton>
                        </div>
                    </motion.div>
                </div>
            </div>
        </>
    )
}
