// Atoms
import { Badge } from "@components/atoms/badge"
import { Button, variants as buttonVariants, LoadingDots } from "@components/atoms/buttons"
import { Spinner } from "@components/atoms/buttons/spinner"
import { DropDown } from "@components/atoms/dropdown"
import { EnerGuide, EnerGuideDark } from "@components/atoms/energuide"
import { ErrorFallback } from "@components/atoms/error-fallback"
import { GradientBorder, GradientBackground } from "@components/atoms/gradient"
import { Image } from "@components/atoms/image"
import { Input, TextAreaInput } from "@components/atoms/input"
import { MarkdownInterpreter } from "@components/atoms/markdown-interpreter"
import { ProgressBar, ProgressIndicator } from "@components/atoms/progress"
import { SmallStat } from "@components/atoms/small-stat"
import { Switch } from "@components/atoms/switch"
import { StyledToaster } from "@components/atoms/toaster"
import { Heading, Text } from "@components/atoms/typography"
// Other
import * as Form from "@radix-ui/react-form"
import { toast } from "sonner"

const Atoms = () => {
    return (
        <section className="border-gray-200 flex flex-col gap-10 border-b pb-8">
            <Heading level="h2">Atoms</Heading>

            {/* Display components in a grid layout */}
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {/* Buttons */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Buttons</Heading>
                    <div className="grid grid-cols-1 gap-4">
                        {Object.keys(buttonVariants)
                            .slice(0, 3)
                            .map((variant) => (
                                <div key={variant} className="flex flex-col gap-2">
                                    <Text variant="body2" className="font-semibold">
                                        {variant}
                                    </Text>
                                    <Button variant={variant as any}>{variant} Button</Button>
                                </div>
                            ))}
                        <div className="flex flex-col gap-2">
                            <Text variant="body2" className="font-semibold">
                                tertiary
                            </Text>
                            <Button variant="tertiary">tertiary Button</Button>
                        </div>
                        <div className="flex flex-col gap-2">
                            <Text variant="body2" className="font-semibold">
                                disabled
                            </Text>
                            <Button variant="primary" disabled>
                                disabled Button
                            </Button>
                        </div>
                        <div className="flex flex-col gap-2">
                            <Text variant="body2" className="font-semibold">
                                disabled
                            </Text>
                            <Button variant="error" disabled>
                                disabled Button
                            </Button>
                        </div>
                        <div className="flex flex-col gap-2">
                            <Text variant="body2" className="font-semibold">
                                loading
                            </Text>
                            <Button variant="primary" disabled>
                                <LoadingDots />
                                Loading
                            </Button>
                        </div>
                    </div>
                </div>

                {/* Typography */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Typography</Heading>
                    <div className="grid grid-cols-1 gap-2">
                        <Heading level="h1">Heading 1</Heading>
                        <Heading level="h2">Heading 2</Heading>
                        <Heading level="h3">Heading 3</Heading>
                        <Heading level="h4">Heading 4</Heading>
                        <Heading level="h5">Heading 5</Heading>
                        <Text variant="body1">Body 1</Text>
                        <Text variant="body2">Body 2</Text>
                        <Text variant="strong1">Strong 1</Text>
                        <Text variant="strong2">Strong 2</Text>
                        <Text variant="label">Label</Text>
                        <Text variant="small">Small</Text>
                        <Text variant="tiny">Tiny</Text>
                    </div>
                </div>

                {/* TextArea */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">TextArea</Heading>
                    <Form.Root className="h-full">
                        <TextAreaInput
                            name="example-textarea"
                            label="Textarea Input"
                            placeholder="Type multiple lines..."
                        />
                    </Form.Root>
                </div>

                {/* Input */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Input</Heading>
                    <Form.Root>
                        <Input name="example-input" label="Text Input" placeholder="Type something..." />
                    </Form.Root>
                </div>

                {/* Progress */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Progress</Heading>
                    <ProgressBar value={65} max={100} />
                    <ProgressIndicator value={75} />
                </div>

                {/* Small Stat */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Small Stat</Heading>
                    <SmallStat name="Users" value="1,245" />
                </div>

                {/* Switch */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Switch</Heading>
                    <Switch id="example-switch" label="Toggle me" />
                </div>

                {/* Toaster */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Toaster</Heading>
                    <Button
                        variant="primary"
                        onClick={() => {
                            toast.info("Example Toast", {
                                description: "This is a toast notification example",
                            })
                        }}
                    >
                        Show Toast
                    </Button>
                    <StyledToaster />
                </div>

                {/* Badge */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Badge</Heading>
                    <Badge label={5} hidden={false}>
                        <Button variant="primary">Notifications</Button>
                    </Badge>
                </div>

                {/* Markdown Interpreter */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Markdown Interpreter</Heading>
                    <div className="border-gray-200 max-h-36 overflow-y-auto rounded-lg border p-4">
                        <MarkdownInterpreter content="## Markdown Example\n\nThis is **bold** text and *italic* text\n\n- List item 1\n- List item 2" />
                    </div>
                </div>

                {/* Error Fallback */}
                <div className="flex flex-col gap-4 overflow-hidden rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Error Fallback</Heading>
                    <div className="relative aspect-square max-h-[25vh] w-full">
                        <div className="absolute inset-0 flex items-center justify-center">
                            <ErrorFallback />
                        </div>
                    </div>
                </div>

                {/* Image */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Image</Heading>
                    <Image
                        src="#"
                        alt="Example image"
                        fallback={<Text variant="body2">Image not available</Text>}
                        className="h-40 w-40"
                    />
                </div>

                {/* Dropdown */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Dropdown</Heading>
                    <DropDown
                        label="Example Dropdown"
                        placeholder="Select an option"
                        items={[
                            { value: "option1", label: "Option 1" },
                            { value: "option2", label: "Option 2" },
                            { value: "option3", label: "Option 3" },
                        ]}
                    />
                </div>

                {/* Gradient Border */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h4">Gradient Border</Heading>
                    <GradientBorder variant="dark" radius="small">
                        <div className="bg-basic-accent p-4">
                            <Text variant="body2">Content with gradient border</Text>
                        </div>
                    </GradientBorder>
                </div>

                {/* Gradient Background */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h4">Gradient Background</Heading>
                    <GradientBackground variant="dark" radius="small">
                        <div className="p-4">
                            <Text variant="body2">Content with gradient background</Text>
                        </div>
                    </GradientBackground>
                </div>

                {/* EnerGuide Bright */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h4">EnerGuide Bright</Heading>
                    <EnerGuide className="aspect-square h-full w-full" energuideClassName="w-full h-full" />
                </div>

                {/* EnerGuide Dark */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h4">EnerGuide Dark</Heading>
                    <EnerGuideDark className="aspect-square h-full w-full" energuideClassName="w-full h-full" />
                </div>

                {/* Loading Dots */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h4">Loading Dots</Heading>
                    <LoadingDots />
                </div>

                {/* Spinner */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h4">Spinner</Heading>
                    <Spinner />
                </div>
            </div>
        </section>
    )
}

export default Atoms
