import { ErrorFallback } from "@components/atoms/error-fallback"
import { Scrollable } from "@components/molecules/scrollable"
import { Appbar } from "@components/organisms/app-bar"
import { Menu } from "@components/organisms/menu"
import { useMobile } from "@hooks/useMobile"
import { useScroll } from "@hooks/useScroll"
import { useAppState } from "@hooks/useState"
import { ErrorBoundary } from "@sentry/react"
import { cls } from "@utils"
import { jwtDecode } from "jwt-decode"
import React from "react"
import { useLocation, useNavigate } from "react-router"

type IAuthenticatedLayout = {
    className?: string
}

const isValidToken = (token: string) => {
    if (!token) {
        return false
    }

    const decodedToken = jwtDecode(token)
    if (!decodedToken.exp) {
        return false
    }

    if (decodedToken.exp < Date.now() / 1000) {
        return false
    }

    return true
}

export function AuthenticatedLayout(props: React.PropsWithChildren<IAuthenticatedLayout>) {
    const { children, className } = props
    const isMobile = useMobile()
    const token = useAppState((state) => state.token)
    const navigate = useNavigate()
    const location = useLocation()
    const { ref: scrollableRef, manualScroll } = useScroll<HTMLDivElement>("top", false, "instant")

    const title = useAppState((state) => state.title)
    const backTo = useAppState((state) => state.backTo)
    const mode = useAppState((state) => state.mode)
    const showContextMenu = useAppState((state) => state.showContextMenu)
    const showSearchIcon = useAppState((state) => state.showSearchIcon)
    const contextMenuProps = useAppState((state) => state.contextMenuProps)
    const backToCallback = useAppState((state) => state.backToCallback)

    //TODO: 12.09.24 Refactor this into a Redirect hook and put it into useRedirects Hook
    React.useEffect(() => {
        if (!isValidToken(token)) {
            navigate("/auth/login-selection")
        }
    }, [token, navigate])

    // automatically scroll to the top when changing between routes
    React.useEffect(() => {
        manualScroll("top")
    }, [manualScroll, location.pathname])

    return (
        <div
            data-name="authenticated-content"
            className={cls("grid h-dvh overflow-hidden", { "grid-cols-[260px_1fr]": !isMobile }, className)}
        >
            {!isMobile ? <Menu mode="desktop" /> : null}
            <Scrollable className="relative h-dvh" ref={scrollableRef}>
                <Appbar
                    mode={mode}
                    backTo={backTo}
                    backToCallback={backToCallback}
                    showSearchIcon={showSearchIcon}
                    showContextMenu={showContextMenu}
                    contextMenuProps={contextMenuProps}
                    title={title}
                />
                <ErrorBoundary fallback={<ErrorFallback />}>{children}</ErrorBoundary>
            </Scrollable>
        </div>
    )
}
