import { variants } from "@components/atoms/buttons"
import * as ToggleGroup from "@radix-ui/react-toggle-group"
import { cls } from "@utils"
import { AnimatePresence } from "framer-motion"
import { motion } from "framer-motion"
import React, { useEffect } from "react"

type IChoiceInput = {
    choices: string[]
    onSelectionChanged?: (value: string) => void
    disabled: boolean
    className: string
    selected?: string
}

export function ChoiceInput(props: IChoiceInput) {
    const { choices, onSelectionChanged, disabled, className, selected: initialSelected } = props
    const [selected, setSelected] = React.useState<string>(initialSelected ?? "")
    useEffect(() => {
        setSelected(initialSelected ?? "")
    }, [initialSelected])

    const onChanged = (value: string) => {
        setSelected(value)
        onSelectionChanged?.(value)
    }

    return (
        <AnimatePresence initial={false}>
            <ToggleGroup.Root
                type="single"
                orientation="vertical"
                value={selected}
                onValueChange={onChanged}
                className={cls("grid gap-4", className)}
                disabled={disabled}
            >
                {choices.map((choice, idx) => (
                    <motion.div
                        initial={{ y: "50px", opacity: 0 }}
                        animate={{ y: "0%", opacity: 1 }}
                        transition={{ delay: 0.2 * idx }}
                        className="grid"
                        key={choice}
                    >
                        <ToggleGroup.Item value={choice} className={cls(variants.primary, "rounded-lg")}>
                            {choice}
                        </ToggleGroup.Item>
                    </motion.div>
                ))}
            </ToggleGroup.Root>
        </AnimatePresence>
    )
}
