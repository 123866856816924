import { Button } from "@components/atoms/buttons"
import * as Popover from "@radix-ui/react-popover"
import { flexRender, Table } from "@tanstack/react-table"

type IFilterPopover<TData> = {
    table: Table<TData>
    filters?: (table: Table<TData>) => JSX.Element
}

export function FilterPopover<TData>(props: IFilterPopover<TData>) {
    const { table, filters } = props
    return (
        <Popover.Root>
            <Popover.Trigger>
                <i className="ri-filter-3-line text-[1.5rem]" />
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    side="bottom"
                    sideOffset={10}
                    align="start"
                    className="bg-basicLight z-40 max-h-80 w-screen overflow-auto rounded-2xl p-6 md:max-w-2xl"
                >
                    {/* Mobile Sort Controls */}
                    <div className="md:hidden">
                        <p className="text-sm mb-2 font-medium">Sortieren:</p>
                        <div className="flex flex-col gap-2">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <div key={headerGroup.id} className="flex flex-col gap-2">
                                    {headerGroup.headers.map((header) =>
                                        header.column.getCanSort() ? (
                                            <Button
                                                key={header.id}
                                                variant="secondary"
                                                className="w-fit"
                                                onClick={() => header.column.toggleSorting()}
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {header.column.getCanSort() &&
                                                    (header.column.getIsSorted() === "asc" ? (
                                                        <i className="ri-arrow-up-line" />
                                                    ) : header.column.getIsSorted() === "desc" ? (
                                                        <i className="ri-arrow-down-line" />
                                                    ) : (
                                                        <i className="ri-arrow-up-down-line" />
                                                    ))}
                                            </Button>
                                        ) : null
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    {filters && (
                        <div className="mt-4 md:mt-0">
                            <p className="text-sm mb-2 font-medium">Filtern:</p>
                            <div className="flex flex-col gap-2">{filters(table)}</div>
                        </div>
                    )}
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    )
}
