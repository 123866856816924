import { Button } from "@components/atoms/buttons"
import { ChatMode } from "@hooks/useChatMode"
import { cls } from "@utils"
import { AnimatePresence, motion } from "framer-motion"

type ChatModeProps = {
    className?: string
    mode: ChatMode
    onClick: () => void
}

export function ChatModeButton(props: ChatModeProps) {
    const { mode, onClick, className } = props
    return (
        <AnimatePresence>
            {mode === ChatMode.offScript && (
                <motion.div
                    className={cls("grid place-items-center", className)}
                    initial={{ y: "50%", opacity: 0 }}
                    animate={{ y: "0%", opacity: 1 }}
                    exit={{ y: "-50%", opacity: 0 }}
                >
                    <Button variant="primary" className="px-4 transition-colors" radius="full" onClick={onClick}>
                        zurück zur Datenaufnahme
                    </Button>
                </motion.div>
            )}
        </AnimatePresence>
    )
}
