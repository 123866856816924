import { Button, LinkButton } from "@components/atoms/buttons"
import { IContextMenu } from "@components/molecules/context-menu"
import { Menu } from "@components/organisms/menu"
import { useSize } from "@hooks/useSize"
import { Modes, useAppState } from "@hooks/useState"
import { cls } from "@utils"
import { motion } from "framer-motion"
import React from "react"

type IAppbar = {
    className?: string
    title?: string
    mode: Modes
    backTo?: string
    backToCallback: (() => void) | null
    style?: React.CSSProperties
    safeSpace?: string
    showSearchIcon?: boolean
} & (
    | {
          showContextMenu: false
      }
    | {
          showContextMenu: true
          contextMenuProps: IContextMenu
      }
)

export function Appbar(props: IAppbar) {
    const { className, backTo, mode, style, backToCallback } = props
    const isHidden = mode === "hide"
    const { ref, size } = useSize<HTMLDivElement>()
    const setAppbarSize = useAppState((state) => state.setAppbarSize)

    React.useLayoutEffect(() => {
        setAppbarSize(size)
    }, [size, setAppbarSize])

    return (
        <motion.div
            className={cls("absolute left-0 right-0 z-40 grid h-14 items-center", className)}
            animate={{
                transform: isHidden ? "translateY(-100%)" : "translateY(0%)",
                opacity: isHidden ? 0 : 1,
            }}
            transition={{ type: "tween" }}
            ref={ref}
        >
            <div className={cls("grid grid-cols-auto-1fr-auto items-center py-2")} style={style}>
                {mode === "menu" && <Menu mode="mobile" />}
                {mode === "back" && (
                    <LinkButton
                        href={backTo ?? ""}
                        onClick={() => backToCallback && backToCallback()}
                        variant="tertiary"
                        className="grid items-center text-[2rem] text-basic-content"
                        dataTestId="back-button"
                    >
                        <i className="ri-arrow-left-s-line text-[1.5rem]"></i>
                    </LinkButton>
                )}
                {mode === "callback" && (
                    <Button
                        onClick={() => backToCallback && backToCallback()}
                        variant="tertiary"
                        className="grid items-center text-[2rem] text-basic-content"
                    >
                        <i className="ri-arrow-left-s-line text-[1.5rem]"></i>
                    </Button>
                )}
            </div>
        </motion.div>
    )
}
