export * from "./loading-dots"
export * from "./link-button"
export * from "./button"

export type Variant = "primary" | "secondary" | "tertiary" | "warning" | "success" | "error" | "freeform"
export const Radius = {
    small: "rounded-[10px]",
    big: "rounded-3xl",
    full: "rounded-full",
}

const basicShape = "p-2 gap-2 min-h-3 flex flex-row justify-center items-center font-button !text-button" //TODO: fix important for text-button here
const basicTransition = "transition duration-200 ease-in-out"
const basicHoverEffect = "hover:scale-[1.02]"
const basicFocusOutline = "focus:outline focus:outline-2 focus:outline-offset-2"
const basicBorder = "border"

const basicDisabled = `
${basicShape}
disabled:hover:scale-100
disabled:bg-primary-disabled
disabled:text-opacity-50
data-[disabled]:hover:scale-100
data-[disabled]:bg-primary-disabled
data-[disabled]:text-opacity-50`

const basicSecondaryDisabled = `
disabled:hover:scale-100
disabled:bg-primary-disabled/5
disabled:text-opacity-50
disabled:text-primary-disabled
disabled:border-primary-disabled
disabled:border-opacity-50
data-[disabled]:hover:scale-100
data-[disabled]:bg-primary-disabled/5
data-[disabled]:text-opacity-50
data-[disabled]:text-primary-disabled
data-[disabled]:border-primary-disabled
data-[disabled]:border-opacity-50
`
const basicSecondary = `
        ${basicShape}
        bg-transparent

        ${basicTransition}
        ${basicHoverEffect}

        ${basicBorder}

        ${basicSecondaryDisabled}
`

export const variants: Record<Variant, string> = {
    primary: `
        ${basicShape}
        bg-primary
        text-primary-content

        ${basicTransition}
        ${basicHoverEffect}

        ${basicFocusOutline}      
        focus:outline-primary

        ${basicDisabled}

        data-[state=on]:bg-primary
        data-[state=on]:text-primary-content
        `,

    secondary: `
        ${basicSecondary}
        bg-primary/5
        text-primary
        border-primary

        ${basicFocusOutline}      
        focus:outline-primary

        data-[state=on]:bg-primary/5
        data-[state=on]:text-primary
        `,

    warning: `
        ${basicSecondary}
        bg-warning/5
        text-warning
        border-warning

        ${basicFocusOutline}      
        focus:outline-warning

        data-[state=on]:bg-warning/5
        data-[state=on]:text-warning
         `,

    error: `
        ${basicSecondary}
        bg-error/5
        text-error
        border-error

        ${basicFocusOutline}      
        focus:outline-error

        data-[state=on]:bg-error/5
        data-[state=on]:text-error
         `,

    success: `
        ${basicSecondary}
        bg-success/5
        text-success
        border-success

        ${basicFocusOutline}      
        focus:outline-success

        data-[state=on]:bg-success/5
        data-[state=on]:text-success
        `,

    tertiary: `
        ${basicShape}
        text-primary

        ${basicTransition}
        ${basicHoverEffect}
        `,
    freeform: "",
}
