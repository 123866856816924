import { Button } from "@components/atoms/buttons"
import { ChatInputFiles } from "@components/molecules/chat-input-files"
import React from "react"
import TextareaAutosize from "react-textarea-autosize"
import { ChatInputBase, SendButton } from "./chat-input-base"

type ChatToolsProps = {
    onPickFile?: () => void
}

function ChatTools(props: ChatToolsProps) {
    const { onPickFile } = props

    return (
        <>
            <Button
                variant="tertiary"
                type="button"
                onClick={onPickFile}
                className="p-0 text-basic-content-light"
                data-testid="chat-attachment-button"
            >
                <i className="ri-attachment-line text-[1.5rem]"></i>
            </Button>
        </>
    )
}

type IChatInput = {
    files: File[]
    message: string
    onMessageChanged: (message: string) => void
    onRemoveFile?: (file: File) => void
    onPickFile?: () => void
}

export const ChatInput = React.forwardRef<HTMLTextAreaElement, IChatInput>((props, ref) => {
    const { message, onMessageChanged, files, onRemoveFile, onPickFile } = props
    const submitButtonRef = React.useRef<HTMLButtonElement | null>(null)

    const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
        if (event.key !== "Enter" || event.shiftKey || !submitButtonRef.current) {
            return
        }

        event.preventDefault()
        submitButtonRef.current?.click()
    }

    return (
        <ChatInputBase
            header={<ChatInputFiles files={files} onRemoveFile={onRemoveFile} />}
            tools={<ChatTools onPickFile={onPickFile} />}
            mainInput={
                <TextareaAutosize
                    autoComplete="off"
                    autoCapitalize="off"
                    autoCorrect="off"
                    data-testid="chat-input"
                    maxRows={5}
                    onKeyDown={onKeyDown}
                    value={message}
                    onInput={(event) => onMessageChanged(event.currentTarget.value)}
                    ref={ref}
                    autoFocus
                />
            }
            sendButton={<SendButton ref={submitButtonRef} />}
        />
    )
})
