// import { RoleSchema } from "../role"
import { ImageSchema, Ref } from "@energuide/shared-common"
import { z } from "zod"
import { AccountTypeSchema } from "../account-type/schema"

const RoleSchema = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    type: z.string(),
})

const UserSchema = z.object({
    id: z.number(),
    username: z.string(),
    email: z.string(),
    provider: z.string(),
    confirmed: z.boolean(),
    blocked: z.boolean(),
    dibtUsername: z.string().nullable().optional(),
    dibtPassword: z.string().nullable().optional(),
    displayName: z.string().nullish(),
    pendingEmail: z.string().nullish(),
    firstname: z.string().nullable(),
    lastname: z.string().nullable(),
    projectLimit: z.number(),
    tokenLimit: z.number(),
    avatar: ImageSchema.nullish(),
    //stripeCustomerId: z.string().nullable(),
    // isDibtUser: z.boolean(),

    role: Ref(RoleSchema),
    accountTypeRef: AccountTypeSchema.nullable().optional(),
})

type IUser = z.infer<typeof UserSchema>

const useUserKey = ["users", "me"]

export { UserSchema, useUserKey }
export type { IUser }
export * from "./test-user"
