import { z } from "zod"
import { useQuery } from "./useQuery"

export function useIsPaidProductId() {
    const queryParams = useQuery()
    const result = z.coerce.number().safeParse(queryParams.get("paid"))
    if (result.success) {
        return result.data
    } else {
        return null
    }
}
