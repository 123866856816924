import { LinkButton } from "@components/atoms/buttons/link-button.tsx"
import { EnerGuide } from "@components/atoms/energuide"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { motion } from "framer-motion"

export function ForgotConfirmation() {
    useTitlebar({
        title: "",
        mode: "back",
        backTo: "/auth/login",
        showContextMenu: false,
    })

    return (
        <>
            <EnerGuide
                glow
                className="absolute inset-0 z-[-1] grid overflow-hidden"
                energuideClassName="absolute top-[-12%] lg:top-[-22%] z-[-1] aspect-square justify-self-center w-[18rem] sm:w-[20rem] md:w-[30rem] lg:w-[22rem] xl:w-[25rem] 2xl:w-[40rem]"
                layoutId="energuide"
            />

            <div className="mx-auto min-h-full w-full max-w-screen-sm content-center pt-4">
                <div className="grid gap-12 rounded-lg bg-basic-strong px-8 py-8 shadow-lg backdrop-blur-2xl">
                    <motion.div className="grid gap-4" layoutId="register-title">
                        <Heading level="h1">Fast geschafft!</Heading>
                        <Text variant="body1" className="text-basic-content-medium">
                            Wir haben dir eine E-Mail zum zurücksetzen deines Passworts geschickt.
                        </Text>
                        <LinkButton
                            variant="primary"
                            href="/auth/login"
                            className="text-black-white underline"
                            dataTestId="email-login-link"
                        >
                            Login
                        </LinkButton>
                    </motion.div>
                </div>
            </div>
        </>
    )
}
