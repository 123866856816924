import { ProjectSchema } from "@energuide/shared-project"
import { UserSchema } from "@energuide/shared-user"
import { z } from "zod"
import { ProductSchema } from "../product"

export enum OrderStatus {
    ongoing = "ongoing",
    paid = "paid",
    reviewed = "reviewed",
    completed = "completed",
    cancelled = "cancelled",
    failedAfterPaid = "failed-after-paid",
}

export const OrderStatusSchema = z.nativeEnum(OrderStatus)

export const OrderSchema = z.object({
    id: z.number(),
    stripeSessionId: z.string().nullish(),
    status: OrderStatusSchema,
    user: UserSchema.optional(), // Ref(UserSchema),
    price: z.string().nullish(),
    product: ProductSchema.optional(),
    workflowId: z.string(),
    project: ProjectSchema.optional(),
    comment: z.string().nullish(),
})

export type IOrder = z.infer<typeof OrderSchema>
