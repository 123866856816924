import { Spinner } from "@components/atoms/buttons/spinner"
import { ChatInputSelect } from "@components/molecules/chat-input-select"
import { motion, AnimatePresence } from "framer-motion"
import React, { FormEventHandler } from "react"
import { useEffect } from "react"
import TextareaAutosize from "react-textarea-autosize"
import { ChatInputBase, SendButton } from "./chat-input-base"

type ChoiceIconProps = {
    loading?: boolean
}

function ChoiceIcon(props: ChoiceIconProps) {
    if (props.loading) {
        return (
            <div className="grid h-6 w-6 place-items-center text-basic-content-light">
                <Spinner />
            </div>
        )
    }

    return <i className="ri-list-check-2 text-[1.5rem] text-basic-content-light"></i>
}

type ChatInputChoiceProps = {
    message: string
    onMessageChanged: (message: string) => void
    choices: string[]
    onSelectionChanged?: (value: string) => void
    disabled?: boolean
    preSelected?: string
    displayMode?: "input" | "list"
    contentRef?: React.RefObject<HTMLDivElement>
}

export const ChatInputChoice = React.forwardRef<HTMLTextAreaElement, ChatInputChoiceProps>((props, ref) => {
    const {
        message,
        onMessageChanged,
        choices,
        onSelectionChanged,
        disabled = false,
        preSelected,
        displayMode = "input",
        contentRef,
    } = props

    const [highlighted, setHighlighted] = React.useState<string>("")
    const [selected, setSelected] = React.useState<string>(preSelected ?? "")
    const [showChoices, setShowChoices] = React.useState<boolean>(true)

    React.useEffect(() => {
        setSelected(preSelected ?? "")
    }, [preSelected])

    // derive options for our select element from the choices
    const selectOptions = React.useMemo(() => {
        return choices
            .map((choice, index) => ({
                label: (
                    <div className="flex w-full flex-row gap-2">
                        <span
                            className="mt-px flex h-5 w-5 items-center justify-center rounded border border-primary/50 bg-transparent p-1 pt-1.5 text-center
                                text-[0.75rem]"
                        >
                            {index + 1}
                        </span>
                        {choice}
                    </div>
                ),
                value: choice,
                index: index + 1,
            }))
            .reverse()
    }, [choices])

    // Filter options based on user input
    const filteredOptions = React.useMemo(() => {
        if (!message.trim()) return selectOptions

        const searchTerm = message.trim().toLowerCase()
        const isNumber = !isNaN(parseInt(searchTerm))

        const filtered = selectOptions.filter((option) => {
            if (isNumber) {
                return option.index === parseInt(searchTerm)
            }
            return option.value.toLowerCase().includes(searchTerm)
        })

        // Immediately select if there's exactly one match
        if (filtered.length === 1) {
            setHighlighted(filtered[0].value)
            setSelected(filtered[0].value)
        }

        return filtered
    }, [message, selectOptions])

    // reset highlighted entry when the list of options changes
    React.useEffect(() => {
        setHighlighted("")
    }, [choices])

    // Check if input is a valid option index
    const checkNumberInput = (input: string) => {
        const num = parseInt(input)
        if (!isNaN(num)) {
            const option = selectOptions.find((opt) => opt.index === num)
            if (option) {
                setHighlighted(option.value)
                setSelected(option.value)
                return true
            }
        }
        return false
    }

    // handle selection of a choice from the list
    const onSelect = (choiceValue: string | null) => {
        if (!choiceValue) {
            return
        }

        setSelected(choiceValue)
        setHighlighted("")
        onMessageChanged("") // Clear the input text when selecting via click
        onSelectionChanged?.(choiceValue) // Submit the selection
        setSelected("") // Reset selection after submitting
    }

    const onTryAccept = () => {
        if (selected) {
            onSelectionChanged?.(selected)
            onMessageChanged("")
            setSelected("")
            return true
        } else if (message.trim()) {
            // Only submit as custom text if it's not trying to be a number
            const num = parseInt(message.trim())
            if (isNaN(num)) {
                onSelectionChanged?.(message)
                onMessageChanged("")
                return true
            }
            // If it was a number but not a valid choice, just clear the input
            onMessageChanged("")
            return false
        }
        return false
    }

    const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
        switch (event.key) {
            case "ArrowDown":
                event.preventDefault()
                onChangeHighlighted("down")
                break
            case "ArrowUp":
                event.preventDefault()
                onChangeHighlighted("up")
                break
            case "Enter":
                event.preventDefault()
                if (!onTryAccept()) {
                    onSelect(highlighted)
                }
                break
        }
    }

    // whenever the user types into the search box:
    // check for number input or handle as custom text
    const onInput: FormEventHandler<HTMLTextAreaElement> = (event) => {
        const value = event.currentTarget.value
        onMessageChanged(value)

        // Show choices if input is empty
        if (!value.trim()) {
            setShowChoices(true)
            return
        }

        if (!checkNumberInput(value)) {
            // If not a valid number input, treat as custom text
            setSelected("")
            setHighlighted("")
            // Only hide choices if no options match the filter
            setShowChoices(filteredOptions.length > 0)
        } else {
            // Show choices when entering numbers
            setShowChoices(true)
        }
    }

    // define keyboard navigation behaviour
    const onChangeHighlighted = (direction: "up" | "down") => {
        if (!highlighted && direction === "up") {
            setHighlighted(selectOptions[0].value)
        } else if (!highlighted && direction === "down") {
            setHighlighted(selectOptions[selectOptions.length - 1].value)
        } else if (direction === "up") {
            const curIdx = selectOptions.findIndex((option) => option.value === highlighted)
            const newIdx = curIdx + 1
            setHighlighted(selectOptions[newIdx % selectOptions.length].value)
        } else if (direction === "down") {
            const curIdx = selectOptions.findIndex((option) => option.value === highlighted)
            const newIdx = curIdx - 1
            setHighlighted(selectOptions[newIdx < 0 ? selectOptions.length - 1 : newIdx].value)
        }
    }

    const toggleChoices = () => {
        setShowChoices(!showChoices)
    }

    // Effect to handle chat view scrolling
    useEffect(() => {
        if (contentRef?.current && showChoices) {
            // Scroll to bottom after a short delay to ensure the choices are rendered
            setTimeout(() => {
                contentRef.current?.scrollTo({
                    top: contentRef.current.scrollHeight,
                    behavior: "smooth",
                })
            }, 300)
        }
    }, [showChoices, filteredOptions, selected])

    if (displayMode === "list") {
        return (
            <div className="grid gap-2">
                <div className="text-sm text-basic-content-light">Wähle eine Option aus:</div>
                <div className="grid gap-0 overflow-hidden rounded-lg border border-black-white/15">
                    {selectOptions
                        .map((option) => (
                            <div
                                key={option.value}
                                className={`cursor-pointer px-2 py-2 ${selected === option.value ? "bg-primary text-primary-content" : "hover:bg-primary/10 "}`}
                                onClick={() => onSelect(option.value)}
                            >
                                {option.label}
                            </div>
                        ))
                        .reverse()}
                </div>
            </div>
        )
    }

    return (
        <ChatInputBase
            header={
                <AnimatePresence>
                    {showChoices && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto", translateZ: 0 }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.2 }}
                        >
                            <ChatInputSelect
                                options={filteredOptions}
                                highlighted={highlighted}
                                onHover={setHighlighted}
                                onSelect={onSelect}
                                disabled={false}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            }
            tools={
                <div onClick={toggleChoices} className="cursor-pointer">
                    <ChoiceIcon />
                </div>
            }
            mainInput={
                <TextareaAutosize
                    autoCapitalize="off"
                    autoComplete="off"
                    autoCorrect="off"
                    maxRows={1}
                    onKeyDown={onKeyDown}
                    onInput={onInput}
                    value={message}
                    placeholder={"Wähle eine Option oder schreibe eine Nachricht..."}
                    className="placeholder-primary-accent/50"
                    ref={ref}
                    data-testid="chat-input"
                    id="chat-input"
                    autoFocus
                    disabled={disabled}
                />
            }
            sendButton={<SendButton type="button" disabled={!selected && !message.trim()} onClick={onTryAccept} />}
        />
    )
})
