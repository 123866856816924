import { cls } from "@utils"

type IBigStat = Omit<React.ComponentProps<"div">, "onClick"> & {
    iconClass: string
    name: string
    value: string
    unit: string
}

export function BigStat(props: IBigStat) {
    const { iconClass, name, value, unit, ...divProps } = props
    return (
        <div {...divProps} className="flex flex-col gap-2 rounded-3xl bg-basic-strong p-4">
            <div className="grid grid-cols-[auto_1fr] items-center gap-2">
                <i className={cls(iconClass, "leading-none text-basic-content")}></i>
                <p className="text-bodyStrong text-basic-content">{name}</p>
            </div>

            <div className="grid">
                <span className="font-heading text-[2rem] font-medium leading-none">{value}</span>
                <p className="text-tiny text-basic-content-medium">{unit}</p>
            </div>
        </div>
    )
}
