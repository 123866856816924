import { apiUrl, handleFetch } from "@api/apiConfig"
import { IChat } from "@api/chat/schema"
import { useCreateChat } from "@api/chat/useCreateChat"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "sonner"

export function useEditChatMessage(projectId: number | null) {
    const queryClient = useQueryClient()
    const authHeader = useAuthHeader()
    const createChatMutation = useCreateChat(projectId)

    return useMutation({
        mutationFn: async (args: { answer: string; chatId: number }) => {
            const { answer, chatId } = args
            if (!authHeader || !projectId) {
                return null
            }

            const resp = await handleFetch(() =>
                fetch(`${apiUrl}/chat/${projectId}`, {
                    method: "PATCH",
                    body: JSON.stringify({
                        answer,
                        chatId,
                    }),
                    headers: {
                        "Content-Type": "application/json", // Make sure to set the Content-Type for JSON
                        ...authHeader,
                    },
                })
            )
            return resp
        },
        // Optimistically update the local cache for the chat
        onMutate: async ({ answer, chatId }) => {
            if (!projectId) {
                return
            }

            await queryClient.cancelQueries({ queryKey: ["chats", projectId] })

            const previousChats = queryClient.getQueryData<IChat[]>(["chats", projectId])
            queryClient.setQueryData<IChat[]>(
                ["chats", projectId],
                (old) => old?.map((chat) => (chat.id === chatId ? { ...chat, content: answer } : chat)) ?? []
            )

            return { previousChats }
        },
        onError: (_err, _variables, context) => {
            // If there's an error, rollback to the previous state
            if (context?.previousChats) {
                queryClient.setQueryData(["chats", projectId], context.previousChats)
            }
            toast.error("Fehler beim Speichern der Antwort")
        },
        onSuccess: async (variables) => {
            toast.success("Antwort erfolgreich gespeichert")
            // Invalidate the chat query to ensure it reflects the latest server state
            await queryClient.invalidateQueries({ queryKey: ["chats", variables.projectId] })
            createChatMutation.mutate({
                intent_type: "init_data_collection",
                message: "",
                files: [],
            })
        },
        onSettled: async () => {
            // Refetch the chats regardless of success or failure
            await queryClient.invalidateQueries({ queryKey: ["chats"] })
        },
    })
}
