import * as ContextMenu from "@radix-ui/react-context-menu"

interface IListItemContextMenu {
    onEdit?: () => void
    onDelete?: () => void
}

function RoomContextMenu({ onEdit, onDelete }: IListItemContextMenu) {
    return (
        <ContextMenu.Portal>
            <ContextMenu.Content className="text-basic flex flex-col gap-2 rounded-lg bg-black-white px-4 py-2 shadow-lg">
                {onEdit && (
                    <ContextMenu.Item color="red" className="cursor-pointer text-small" onClick={onEdit}>
                        Edit
                    </ContextMenu.Item>
                )}
                <ContextMenu.Separator className="solid border-basic-content border-[0.75px]" />
                {onDelete && (
                    <ContextMenu.Item color="red" className="cursor-pointer text-small text-warning" onClick={onDelete}>
                        Delete
                    </ContextMenu.Item>
                )}
            </ContextMenu.Content>
        </ContextMenu.Portal>
    )
}
export { RoomContextMenu }
