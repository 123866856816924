import { useHeight } from "@hooks/useHeight"
import { AnimatePresence, motion } from "framer-motion"
import { PreviewImage } from "./preview-image"

type ChatSelectProps = {
    files: File[]
    onRemoveFile?: (file: File) => void
}

export function ChatInputFiles(props: ChatSelectProps) {
    const { files, onRemoveFile } = props
    const { height } = useHeight(files.length > 0)

    return (
        <div className="col-span-3 flex gap-2 px-5">
            <AnimatePresence initial={false}>
                {files.map((file, idx) => {
                    return (
                        <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height, opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ type: "tween" }}
                            className="overflow-hidden"
                            key={idx}
                        >
                            <PreviewImage
                                alt={"Ausgewählte Datei für Upload"}
                                path={URL.createObjectURL(file)}
                                onRemove={() => onRemoveFile?.(file)}
                                className="pb-2 pr-3 pt-3"
                                filename={file.name}
                            />
                        </motion.div>
                    )
                })}
            </AnimatePresence>
        </div>
    )
}
