// Atoms
import { Button } from "@components/atoms/buttons"
import { Heading, Text } from "@components/atoms/typography"
import { ChatCards } from "@components/organisms/chat-cards"
// Organisms
import { ChatInput } from "@components/organisms/chat-inputs/chat-input"
import { ChatInputChoice } from "@components/organisms/chat-inputs/chat-input-choice"
import { ChatInputLocation } from "@components/organisms/chat-inputs/chat-input-location"
import { ChatMessages } from "@components/organisms/chat-messages"
import { DataBlock } from "@components/organisms/data-block"
import { DeleteAccount } from "@components/organisms/delete-account"
import { EditAnswer } from "@components/organisms/edit-answer"
import { ItemList } from "@components/organisms/item-list"
import LocationModal from "@components/organisms/location-modal"
import { Reveal } from "@components/organisms/reveal"
import { TeamMembers } from "@components/organisms/team-members"
import { Location } from "@energuide/shared-project"
// Other
import { FormEvent, useState } from "react"
import { toast } from "sonner"

const Organisms = () => {
    // Mock chat state
    const [chatMessage, setChatMessage] = useState("")
    const [chatFiles, setChatFiles] = useState<File[]>([])
    const [choiceMessage, setChoiceMessage] = useState("")
    const [selectedChoice, setSelectedChoice] = useState<string | undefined>()
    const [locationModalOpen, setLocationModalOpen] = useState(false)
    // Mock chat data
    const mockChats = [
        {
            id: 1,
            role: "assistant" as const,
            content: "Hello! I'm your AI assistant. How can I help you today?",
            display: "default" as const,
            files: [],
            intent_type: null,
            lastUpdate: new Date(),
            displayKey: 1,
            task_updates: [],
        },
        {
            id: 2,
            role: "user" as const,
            content: "I need information about energy certificates.",
            sender: {
                displayName: "John Doe",
                avatar: null,
            },
            display: "default" as const,
            files: [],
            intent_type: null,
            lastUpdate: new Date(),
            displayKey: 2,
            task_updates: [],
        },
        {
            id: 3,
            role: "assistant" as const,
            content:
                "Energy certificates provide information about a building's energy efficiency. Would you like to know about specific types?",
            choices: ["Demand Certificate", "Consumption Certificate", "General Information"],
            display: "default" as const,
            files: [],
            intent_type: null,
            lastUpdate: new Date(),
            displayKey: 3,
            task_updates: [],
        },
    ]

    // Mock team members data
    const mockMembers = [
        {
            avatarSrc: "",
            name: "John Doe",
            role: { id: 1, role: "Administrator" },
        },
        {
            avatarSrc: "#",
            name: "Jane Smith",
            role: { id: 2, role: "Editor" },
        },
        {
            avatarSrc: "",
            name: "Robert Johnson",
            role: { id: 3, role: "Viewer" },
        },
    ]

    const mockProjectRoles = [
        { id: 1, role: "Administrator" },
        { id: 2, role: "Editor" },
        { id: 3, role: "Viewer" },
    ]

    const handleAddTeamMember = async (data: FormData) => {
        toast.success(`Added team member: ${data.get("email")}`)
        return Promise.resolve()
    }

    return (
        <section className="border-gray-200 flex flex-col gap-10 border-b pb-8">
            <Heading level="h2">Organisms</Heading>
            {/* Display components in a grid layout */}
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                {/* Chat Input */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Chat Input</Heading>
                    <div className="max-w-full">
                        {/* Add wrapper to prevent auto-focus */}
                        <div className="prevent-autofocus">
                            <ChatInput
                                message={chatMessage}
                                onMessageChanged={setChatMessage}
                                files={chatFiles}
                                onRemoveFile={(file) => {
                                    setChatFiles(chatFiles.filter((f) => f !== file))
                                }}
                                onPickFile={() => toast.info("File picker would open here")}
                                // Force using a custom ref that won't auto-focus
                                ref={(el) => el && el.blur && el.blur()}
                            />
                        </div>
                    </div>
                </div>

                {/* Chat Input with Choices */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Chat Input with Choices</Heading>
                    <div className="max-w-full">
                        {/* Add wrapper to prevent auto-focus */}
                        <div className="prevent-autofocus">
                            <ChatInputChoice
                                message={choiceMessage}
                                onMessageChanged={setChoiceMessage}
                                choices={["Option 1", "Option 2", "Option 3"]}
                                onSelectionChanged={setSelectedChoice}
                                preSelected={selectedChoice}
                                displayMode="input"
                                // Force using a custom ref that won't auto-focus
                                ref={(el) => el && el.blur && el.blur()}
                            />
                        </div>
                    </div>
                </div>

                {/* Chat Input with Location */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Chat Input with Location</Heading>
                    <div className="max-w-full">
                        {/* Add wrapper to prevent auto-focus */}
                        <div className="prevent-autofocus">
                            <ChatInputLocation
                                // Force using a custom ref that won't auto-focus
                                ref={(el) => el && el.blur && el.blur()}
                                message={""}
                                onMessageChanged={function (): void {
                                    throw new Error("Function not implemented.")
                                }}
                                onAddressChanged={function (): void {
                                    throw new Error("Function not implemented.")
                                }}
                                onLocationChanged={function (): void {
                                    throw new Error("Function not implemented.")
                                }}
                            />
                        </div>
                    </div>
                </div>

                {/* Chat Input with Choices */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Choices Modal Selection</Heading>
                    <div className="max-w-full">
                        {/* Add wrapper to prevent auto-focus */}
                        <div className="prevent-autofocus">
                            <ChatInputChoice
                                message={choiceMessage}
                                onMessageChanged={setChoiceMessage}
                                choices={["Option 1", "Option 2", "Option 3"]}
                                onSelectionChanged={setSelectedChoice}
                                preSelected={selectedChoice}
                                displayMode="list"
                                // Force using a custom ref that won't auto-focus
                                ref={(el) => el && el.blur && el.blur()}
                            />
                        </div>
                    </div>
                </div>

                {/* Chat Card */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Chat Cards</Heading>
                    <ChatCards onClick={() => {}} hints={[{ id: 1, question: "What is the capital of France?" }]} />
                </div>

                {/* Chat Messages */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Chat Messages</Heading>
                    <div className="flex max-h-60 flex-col gap-8 overflow-auto">
                        <ChatMessages
                            chats={mockChats}
                            projectId={1}
                            onChoiceSelected={(message) => {
                                toast.info(`Selected: ${message}`)
                                setSelectedChoice(message)
                            }}
                            onNewChoices={(choices) => {
                                console.log("New choices available:", choices)
                            }}
                        />
                    </div>
                </div>

                {/* Team Members */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Team Members</Heading>
                    <TeamMembers members={mockMembers} projectRoles={mockProjectRoles} onAdd={handleAddTeamMember} />
                </div>

                {/* DataBlock */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Data Block</Heading>
                    <DataBlock
                        title="User Information"
                        sections={[
                            {
                                heading: "Basic Information",
                                entries: [
                                    { label: "Name", name: "name", value: "John Doe" },
                                    { label: "Email", name: "email", value: "john@example.com" },
                                ],
                            },
                        ]}
                        defaultOpen={true}
                    />
                </div>

                {/* ItemList */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Item List</Heading>
                    <ItemList
                        title="Project Items"
                        items={[
                            { id: "1", title: "Item 1", value: "Value 1" },
                            { id: "2", title: "Item 2", value: "Value 2" },
                        ]}
                        icon={<i className="ri-list-check"></i>}
                    />
                </div>

                {/* Reveal */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Reveal</Heading>
                    <Reveal title="Click to Reveal" defaultOpen={false}>
                        <div className="p-4">
                            <Text variant="body1">This content can be revealed by clicking the title</Text>
                        </div>
                    </Reveal>
                </div>

                {/* Delete Account */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Delete Account</Heading>
                    <Text variant="body2">
                        This component is used to delete the user's account.{" "}
                        <span className="font-bold text-error">
                            Don't accept the modal, as it will delete your account!
                        </span>
                    </Text>
                    <DeleteAccount />
                </div>

                {/* Edit Answer */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Edit Answer</Heading>
                    <EditAnswer chatId={-1} projectId={-1} />
                </div>

                {/* Location Modal */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Location Modal</Heading>
                    <Button variant="primary" onClick={() => setLocationModalOpen(true)}>
                        Open Location Modal
                    </Button>
                    <LocationModal
                        open={locationModalOpen}
                        onOpenChange={function (open: boolean): void {
                            setLocationModalOpen(open)
                        }}
                        onSubmit={function (event: FormEvent<HTMLFormElement>): void {
                            event.preventDefault()
                        }}
                        center={null}
                        onCenterChange={function (center: Location): void {
                            console.log(center)
                        }}
                    />
                </div>

                {/* Sheet */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Sheet</Heading>
                    <Text variant="body2">
                        Failed to load sheet component due to missing dependencies: Failed to resolve import
                        "class-variance-authority"
                    </Text>
                </div>
            </div>
        </section>
    )
}

export default Organisms
