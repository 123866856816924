import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"
import { WGVDatenSchema } from "./schema"


export function transform(wgvDaten: any) {
    if (!wgvDaten) { return null }
    return {
        id: wgvDaten.id,
        ...wgvDaten.attributes,
    }
}

type IUseEtDaten = {
    enabled?: boolean
}

export function useWGVDaten(projectId: number | null, params?: IUseEtDaten) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["wgv-datens", projectId],
        queryFn: async () => {
            const response = await handleFetch(async () => {
                const response = await fetch(`${apiUrl}/wgv-datens?projectId=${projectId}`, {
                    headers: {
                        ...authHeader,
                    },
                })

                if (response.status === 404) {
                    return null
                }

                return response
            })

            if (!response) {
                return null
            }

            const wgvDaten = handleParse(() => WGVDatenSchema.parse(response ? transform(response.data) : null))
            return wgvDaten
        },
        enabled: enabled && !!authHeader && !!projectId,
    })
}
