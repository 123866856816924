import { Button } from "@components/atoms/buttons"
import { Heading, Text } from "@components/atoms/typography"
import * as AlertDialog from "@radix-ui/react-alert-dialog"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"

type IConfirmation = {
    trigger: string
    title: string
    description: string
    cancel: string
    action: string

    onConfirm: () => Promise<void>
}
export function Confirmation(props: IConfirmation) {
    const { trigger, title, description, cancel, action, onConfirm } = props
    const [open, setOpen] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()

        try {
            setLoading(true)
            await onConfirm()
            setOpen(false)
        } finally {
            setLoading(false)
        }
    }

    return (
        <AlertDialog.Root onOpenChange={setOpen} open={open}>
            <AlertDialog.Trigger asChild>
                <Button variant="error" data-testid="confirmation-trigger-button">
                    {trigger}
                </Button>
            </AlertDialog.Trigger>

            <AnimatePresence>
                {open && (
                    <AlertDialog.Portal forceMount>
                        <AlertDialog.Overlay
                            className="fixed bottom-0 left-0 right-0 top-0"
                            onClick={() => setOpen(false)}
                        >
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{
                                    ease: "circInOut",
                                }}
                                className="h-full w-full bg-basic/50 backdrop-blur-sm"
                            ></motion.div>
                        </AlertDialog.Overlay>

                        <AlertDialog.Content forceMount asChild>
                            <motion.div
                                className="fixed left-[50%] top-[50%] z-30 grid w-full translate-x-[-50%] translate-y-[-50%] grid-cols-1 gap-6 rounded-3xl border
                                    border-black-white/10 bg-basic-accent p-6 md:max-w-xl"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{
                                    ease: "circInOut",
                                }}
                            >
                                <AlertDialog.Title className="font-heading text-heading3" asChild>
                                    <Heading level="h3">{title}</Heading>
                                </AlertDialog.Title>
                                <AlertDialog.Description asChild>
                                    <Text variant="body2">{description}</Text>
                                </AlertDialog.Description>

                                <form className="grid grid-cols-2 gap-4" onSubmit={onSubmit}>
                                    <AlertDialog.Cancel asChild>
                                        <Button variant="secondary" data-testid="confirmation-cancel-button">
                                            {cancel}
                                        </Button>
                                    </AlertDialog.Cancel>
                                    <AlertDialog.Action asChild>
                                        <Button
                                            type="submit"
                                            variant="warning"
                                            loading={loading}
                                            data-testid="confirmation-confirm-button"
                                        >
                                            {action}
                                        </Button>
                                    </AlertDialog.Action>
                                </form>
                            </motion.div>
                        </AlertDialog.Content>
                    </AlertDialog.Portal>
                )}
            </AnimatePresence>
        </AlertDialog.Root>
    )
}
