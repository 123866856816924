import { z } from "zod"

export const FeatureSchema = z.object({
    type: z.string(),
    id: z.string(),
    geometry: z.object({
        type: z.string(),
        coordinates: z.array(z.number()),
    }),
    properties: z.object({
        name: z.string(),
        full_address: z.string(),
        context: z.object({
            address: z
                .object({
                    address_number: z.string(),
                    street_name: z.string(),
                })
                .optional(),
            country: z
                .object({
                    country_code: z.string(),
                    name: z.string(),
                })
                .optional(),
            place: z
                .object({
                    name: z.string(),
                })
                .optional(),
            postcode: z
                .object({
                    name: z.string(),
                })
                .optional(),
            region: z
                .object({
                    name: z.string(),
                    region_code: z.string(),
                    region_code_full: z.string(),
                })
                .optional(),
            street: z
                .object({
                    name: z.string(),
                })
                .optional(),
        }),
        match_code: z
            .object({
                confidence: z
                    .union([z.literal("exact"), z.literal("high"), z.literal("medium"), z.literal("low")])
                    .optional(),
            })
            .optional(),
    }),
})

export const SearchResponseSchema = z.object({
    type: z.string(),
    features: z.array(FeatureSchema),
})
