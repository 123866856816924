import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"
import { GEGDatenSchema } from "./schema"

export function transform(gegDaten: any) {
  if (!gegDaten) { return null }
  return {
    id: gegDaten.id,
    ...gegDaten.attributes,
  }
}

type IUseGEGDaten = {
  enabled?: boolean
}

export function useGEGDaten(projectId: number | null, params?: IUseGEGDaten) {
  const { enabled = true } = params ?? {}
  const authHeader = useAuthHeader()

  return useQuery({
    queryKey: ["geg-datens", projectId],
    queryFn: async () => {
      const response = await handleFetch(async () => {
        const response = await fetch(`${apiUrl}/geg-datens?projectId=${projectId}`, {
          headers: {
            ...authHeader,
          },
        })

        if (response.status === 404) {
          return null
        }

        return response
      })

      if (!response) {
        return null
      }

      const gegDaten = handleParse(() => GEGDatenSchema.parse(response ? transform(response.data) : null))
      return gegDaten
    },
    enabled: enabled && !!authHeader && !!projectId,
  })
} 