import { z } from "zod"


export const GEGDatenSchema = z.object({
  Registriernummer: z.string().max(20).nullish(),
  Bundesland: z.string().nullish(),
  Gebaeudeteil: z.string().nullish(),
  Wesentliche_Energietraeger_Heizung: z.string().nullish(),
  Wesentliche_Energietraeger_Warmwasser: z.string().nullish(),
  Erneuerbare_Art: z.string().nullish(),
  Erneuerbare_Verwendung: z.string().nullish(),
  Lueftungsart_Fensterlueftung: z.boolean().nullish(),
  Lueftungsart_Schachtlueftung: z.boolean().nullish(),
  Lueftungsart_Anlage_o_WRG: z.boolean().nullish(),
  Lueftungsart_Anlage_m_WRG: z.boolean().nullish(),
  Kuehlungsart_Passive_Kuehlung: z.boolean().nullish(),
  Kuehlungsart_Strom: z.boolean().nullish(),
  Kuehlungsart_Waerme: z.boolean().nullish(),
  Kuehlungsart_gelieferte_Kaelte: z.boolean().nullish(),
  Keine_inspektionspflichtige_Anlage: z.boolean().nullish(),
  Anzahl_Klimanlagen: z.number().min(1).max(1000).nullish(),
  Anlage_groesser_12kW_ohneGA: z.boolean().nullish(),
  Anlage_groesser_12kW_mitGA: z.boolean().nullish(),
  Anlage_groesser_70kW: z.boolean().nullish(),
  Faelligkeitsdatum_Inspektion: z.string().nullish(),
  Nutzung_zur_Erfuellung_von_EE_neue_Anlage: z.boolean().nullish(),
  EE_Angabe_Warmwasser: z.boolean().nullish(),
  EE_Angabe_Heizung: z.boolean().nullish(),
  Keine_Pauschale_Erfuellungsoptionen_Anlagentyp: z.boolean().nullish(),
  Hausuebergabestation: z.boolean().nullish(),
  Waermepumpe: z.boolean().nullish(),
  Stromdirektheizung: z.boolean().nullish(),
  Solarthermische_Anlage: z.boolean().nullish(),
  Heizungsanlage_Biomasse_Wasserstoff_Wasserstoffderivale: z.boolean().nullish(),
  Waermepumpen_Hybridheizung: z.boolean().nullish(),
  Solarthermie_Hybridheizung: z.boolean().nullish(),
  Dezentral_elektrische_Warmwasserbereitung: z.boolean().nullish(),
  Nutzung_bei_Bestandsanlagen: z.boolean().nullish(),
  Ausstellungsanlass: z.string().nullish(),
  Datenerhebung_Aussteller: z.boolean().nullish(),
  Datenerhebung_Eigentuemer: z.boolean().nullish(),
  Empfehlungen_moeglich: z.boolean().nullish(),
  Keine_Modernisierung_Erweiterung_Vorhaben: z.boolean().nullish(),
  Modernisierung_Erweiterung_anzeigepflichtiges_Vorhaben: z.boolean().nullish(),
  Modernisierung_Erweiterung_genehmigungspflichtiges_Vorhaben: z.boolean().nullish(),
  Softwarehersteller_Programm_Version: z.string().nullish(),
  Gesetzesgrundlage: z.string().nullish(),
  Rechtsstand: z.string().nullish(),
  Rechtsstand_Grund: z.string().nullish(),
  WGDaten: z.any().nullish(),
  NWGDaten: z.any().nullish(),
  MZeile: z.array(z.any()).nullish(),
  Baujahr_Gebaeude: z.number().nullish(),
  Baujahr_Waermeerzeuger: z.number().nullish()
}).nullable()

export type IGEGDaten = z.infer<typeof GEGDatenSchema> 