// Atoms
import { Heading } from "@components/atoms/typography"
import SwitchThemeButton from "@components/molecules/switch-theme"
// Organisms
import { useEffect, useRef, useState } from "react"
import Atoms from "./parts/atoms"
import Colors from "./parts/colors"
import Molecules from "./parts/molecules"
import Organisms from "./parts/organisms"
import Advanced from "./parts/advanced"

// Component type enum for filtering
type ComponentType = "all" | "colors" | "atoms" | "molecules" | "organisms" | "advanced"

const ComponentsPage = () => {
    // Add state for tab selection
    const [activeTab, setActiveTab] = useState<ComponentType>("all")
    const [visibleSection, setVisibleSection] = useState<ComponentType>("all")
    const tabsRef = useRef<HTMLDivElement>(null)
    
    // Section refs
    const colorsRef = useRef<HTMLDivElement>(null)
    const atomsRef = useRef<HTMLDivElement>(null)
    const moleculesRef = useRef<HTMLDivElement>(null)
    const organismsRef = useRef<HTMLDivElement>(null)
    const advancedRef = useRef<HTMLDivElement>(null)

    // Setup intersection observer for section visibility
    useEffect(() => {
        // Only observe sections when on the "all" tab
        if (activeTab !== "all") {
            setVisibleSection(activeTab)
            return
        }

        const options = {
            rootMargin: '-100px 0px -60% 0px', // Adjust to control when section is considered visible
            threshold: 0
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const id = entry.target.getAttribute('data-section') as ComponentType
                    if (id) {
                        setVisibleSection(id)
                    }
                }
            })
        }, options)

        // Observe all section refs
        if (colorsRef.current) {
            colorsRef.current.setAttribute('data-section', 'colors')
            observer.observe(colorsRef.current)
        }

        if (atomsRef.current) {
            atomsRef.current.setAttribute('data-section', 'atoms')
            observer.observe(atomsRef.current)
        }

        if (moleculesRef.current) {
            moleculesRef.current.setAttribute('data-section', 'molecules')
            observer.observe(moleculesRef.current)
        }

        if (organismsRef.current) {
            organismsRef.current.setAttribute('data-section', 'organisms')
            observer.observe(organismsRef.current)
        }

        if (advancedRef.current) {
            advancedRef.current.setAttribute('data-section', 'advanced')
            observer.observe(advancedRef.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [activeTab])

    // Scroll to active tab when changed
    const scrollToTab = (tab: ComponentType) => {
        setActiveTab(tab)

    }

    // Tabs configuration
    const tabs: { id: ComponentType; label: string }[] = [
        { id: "all", label: "All" },
        { id: "colors", label: "Colors" },
        { id: "atoms", label: "Atoms" },
        { id: "molecules", label: "Molecules" },
        { id: "organisms", label: "Organisms" },
        { id: "advanced", label: "Advanced" },
    ]

    return (
        <div className="flex flex-col gap-6">
            {/* Sticky tabs navigation - enhanced design */}
            <div
                ref={tabsRef}
                className={`sticky top-0 z-50 bg-basic`}
            >
                <div className="mx-auto flex max-w-7xl items-center px-4 py-2">
                    <div className="scrollbar-hide flex w-full gap-1 overflow-x-auto pb-1 pt-1 sm:justify-center">
                        <SwitchThemeButton />
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                onClick={() => scrollToTab(tab.id)}
                                className={`text-sm relative min-w-[100px] flex-none rounded-md px-4 py-2 font-medium transition-all ${
                                    activeTab === tab.id
                                        ? "bg-primary text-white"
                                        : activeTab === "all" && visibleSection === tab.id
                                          ? "bg-primary/10 text-basic-content" // Highlight when section is visible in "all" tab
                                          : "text-basic-content hover:bg-primary/10"
                                }`}
                            >
                                {tab.label}
                                {activeTab === tab.id && (
                                    <span className="bg-primary-tint absolute bottom-0 left-0 right-0 mx-auto h-0.5 w-4/5 rounded-full"></span>
                                )}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-10 p-6">
                <Heading level="h1" className="mb-4">
                    Component Library
                </Heading>

                {/* Show sections based on active tab */}
                {(activeTab === "all" || activeTab === "colors") && (
                    <div ref={colorsRef}><Colors /></div>
                )}

                {(activeTab === "all" || activeTab === "atoms") && (
                    <div ref={atomsRef}><Atoms /></div>
                )}

                {(activeTab === "all" || activeTab === "molecules") && (
                    <div ref={moleculesRef}><Molecules /></div>
                )}

                {(activeTab === "all" || activeTab === "organisms") && (
                    <div ref={organismsRef}><Organisms /></div>
                )}

                {(activeTab === "all" || activeTab === "advanced") && (
                    <div ref={advancedRef}>
                        <Advanced />
                    </div>
                )}
            </div>
        </div>
    )
}

export default ComponentsPage
