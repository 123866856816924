// Atoms
import { Button } from "@components/atoms/buttons"
import { Input } from "@components/atoms/input"
import { ProgressBar } from "@components/atoms/progress"
import { Heading, Text } from "@components/atoms/typography"
// Molecules
import { UserAvatar } from "@components/molecules/avatar"
import { BigStat } from "@components/molecules/big-stat"
import { ChatInputFiles } from "@components/molecules/chat-input-files"
import { ChatInputSelect } from "@components/molecules/chat-input-select"
import { ChatModeButton } from "@components/molecules/chat-mode-button"
import { ChatProjectSelect } from "@components/molecules/chat-project-select"
import { ChoiceInput } from "@components/molecules/choice-input"
import { Confirmation } from "@components/molecules/confirmation"
import { ContentOffset } from "@components/molecules/content-offset"
import { ContextMenu } from "@components/molecules/context-menu"
import { DataTable } from "@components/molecules/data-table"
import Dialog from "@components/molecules/dialog"
import { InfoCard } from "@components/molecules/info-card"
import Overlay from "@components/molecules/overlay/overlay"
import { PaymentMethods } from "@components/molecules/payment-methods"
import { PreviewImage } from "@components/molecules/preview-image"
import { ProjectList } from "@components/molecules/project-list"
import { Scrollable } from "@components/molecules/scrollable"
import { SearchInput } from "@components/molecules/search-input"
import { Select } from "@components/molecules/select"
import SwitchThemeButton from "@components/molecules/switch-theme"
import { ChatCards } from "@components/organisms/chat-cards"
import { DataModal } from "@components/organisms/data-modal"
import { ChatMode } from "@hooks/useChatMode"
// Other
import * as DialogPrimitive from "@radix-ui/react-dialog"
import * as Form from "@radix-ui/react-form"
import { AnimatePresence, motion } from "framer-motion"
import { useState } from "react"
import { toast } from "sonner"

const Molecules = () => {
    const handleSearch = (term: string): HTMLElement[] => {
        console.log("Searching for:", term)
        return []
    }

    // For async functions
    const mockPromise = async () => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve()
            }, 1000)
        })
    }

    // Mock functions for various components
    const handleClick = () => console.log("Clicked")

    // Mock state for dialog
    const [dialogOpen, setDialogOpen] = useState(false)

    // Mock state for data modal
    const [dataModalOpen, setDataModalOpen] = useState(false)

    // Mock state for sheet
    const [sheetOpen, setSheetOpen] = useState(false)

    // For PaymentMethods
    const mockPaymentMethods = [
        { iconClass: "ri-visa-line", name: "Visa", ending: "4242" },
        { iconClass: "ri-mastercard-line", name: "Mastercard", ending: "5555" },
    ]

    return (
        <section className="border-gray-200 flex flex-col gap-10 border-b pb-8">
            <Heading level="h2">Molecules</Heading>

            {/* Display basic components in a grid layout */}
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {/* Big Stat */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Big Stat</Heading>
                    <BigStat
                        iconClass="ri-money-dollar-circle-line text-[2rem]"
                        name="Monthly Revenue"
                        value="12,345"
                        unit="USD"
                    />
                </div>

                {/* Search Input */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Search Input</Heading>
                    <SearchInput onSearch={handleSearch} />
                </div>

                {/* Select */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Select</Heading>
                    <Select
                        id="example-select"
                        label="Choose an option"
                        options={[
                            { value: "option1", label: "Option 1" },
                            { value: "option2", label: "Option 2" },
                            { value: "option3", label: "Option 3" },
                        ]}
                        defaultValue="option1"
                    />
                </div>

                {/* Info Card */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Info Card</Heading>
                    <InfoCard title="Information Card" onClick={handleClick}>
                        <Text variant="body2">This is an information card with content</Text>
                    </InfoCard>
                </div>

                {/* PreviewImage */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Preview Image</Heading>
                    <PreviewImage path="#" alt="Preview example" filename="example.jpg" onRemove={handleClick} />
                </div>

                {/* Choice Input */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Choice Input</Heading>
                    <ChoiceInput
                        choices={["Option 1", "Option 2", "Option 3"]}
                        onSelectionChanged={(value) => console.log("Selected:", value)}
                        disabled={false}
                        className="w-full"
                        selected="Option 1"
                    />
                </div>

                {/* Confirmation Dialog */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Confirmation Dialog</Heading>
                    <Confirmation
                        trigger="Show Confirmation"
                        title="Confirm Action"
                        description="Are you sure you want to perform this action?"
                        cancel="Cancel"
                        action="Confirm"
                        onConfirm={mockPromise}
                    />
                </div>

                {/* User Avatar */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">User Avatar</Heading>
                    <div className="flex gap-4">
                        <UserAvatar src="" alt="User without image" initials="JD" variant="big" />
                        <UserAvatar src="#" alt="User with image" initials="JS" variant="chat" />
                    </div>
                </div>

                {/* Switch Theme Button */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Switch Theme Button</Heading>
                    <SwitchThemeButton />
                </div>

                {/* Scrollable component */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Scrollable</Heading>
                    <div className="border-gray-200 overflow-hidden rounded-lg border">
                        <div className="h-[200px] w-full">
                            <Scrollable>
                                {Array.from({ length: 20 }).map((_, i) => (
                                    <Text key={i} variant="body2" className="px-4 py-2">
                                        Scrollable content line {i + 1}
                                    </Text>
                                ))}
                            </Scrollable>
                        </div>
                    </div>
                </div>

                {/* Data table */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Data Table</Heading>
                    <DataTable
                        columns={[
                            {
                                accessorKey: "name",
                                header: "Name",
                                cell: (info) => info.getValue(),
                            },
                            {
                                accessorKey: "age",
                                header: "Age",
                                cell: (info) => info.getValue(),
                            },
                            {
                                accessorKey: "status",
                                header: "Status",
                                cell: (info) => info.getValue(),
                            },
                        ]}
                        data={[
                            { name: "John Doe", age: 32, status: "Active" },
                            { name: "Jane Smith", age: 28, status: "Inactive" },
                            { name: "Robert Johnson", age: 45, status: "Active" },
                        ]}
                    />
                </div>

                {/* ChatCards */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Chat Cards</Heading>
                    <ChatCards
                        hints={[
                            { id: 1, question: "What is Enerki?" },
                            { id: 2, question: "How do I create a project?" },
                        ]}
                        onClick={(message) => console.log("Card clicked with message:", message)}
                    />
                </div>

                {/* Dialog Example */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Dialog</Heading>
                    <Button variant="primary" onClick={() => setDialogOpen(true)}>
                        Open Dialog
                    </Button>

                    <DialogPrimitive.Root open={dialogOpen} onOpenChange={setDialogOpen}>
                        <AnimatePresence>
                            {dialogOpen && (
                                <DialogPrimitive.Portal forceMount>
                                    <DialogPrimitive.Overlay className="fixed bottom-0 left-0 right-0 top-0" asChild>
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            className="h-full w-full bg-basic/50 backdrop-blur-sm"
                                        />
                                    </DialogPrimitive.Overlay>
                                    <DialogPrimitive.Content className="fixed left-[50%] top-[50%] z-30 w-full max-w-lg translate-x-[-50%] translate-y-[-50%]">
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            className="border-1 grid max-h-[100dvh] w-full gap-3 rounded-3xl border border-black-white/10 bg-basic-accent p-6 pt-3 md:max-w-xl"
                                        >
                                            <DialogPrimitive.Title asChild>
                                                <Heading level="h3">Dialog Example</Heading>
                                            </DialogPrimitive.Title>
                                            <DialogPrimitive.Description asChild>
                                                <Text variant="body2" className="text-basic-content-medium">
                                                    This is an example dialog with custom content
                                                </Text>
                                            </DialogPrimitive.Description>
                                            <div className="grid gap-4">
                                                <Form.Root>
                                                    <Input
                                                        name="dialog-input"
                                                        label="Sample Input"
                                                        placeholder="Enter some text..."
                                                    />
                                                </Form.Root>
                                                <div className="flex justify-end gap-2">
                                                    <Button variant="secondary" onClick={() => setDialogOpen(false)}>
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => {
                                                            toast.success("Dialog action confirmed")
                                                            setDialogOpen(false)
                                                        }}
                                                    >
                                                        Confirm
                                                    </Button>
                                                </div>
                                            </div>
                                        </motion.div>
                                    </DialogPrimitive.Content>
                                </DialogPrimitive.Portal>
                            )}
                        </AnimatePresence>
                    </DialogPrimitive.Root>
                </div>

                {/* DataModal */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Data Modal</Heading>
                    <Button variant="primary" onClick={() => setDataModalOpen(true)}>
                        Open Data Modal
                    </Button>

                    <DataModal
                        iconClass="ri-database-line"
                        title="Data Modal Example"
                        description="This modal is used for displaying and editing data"
                        open={dataModalOpen}
                        onOpenChange={setDataModalOpen}
                    >
                        <div className="grid gap-4 p-4">
                            <Form.Root>
                                <Input name="name" label="Name" placeholder="Enter name..." />
                                <Input name="email" label="Email" placeholder="Enter email..." className="mt-4" />
                            </Form.Root>
                            <div className="mt-4 flex justify-end gap-2">
                                <Button variant="secondary" onClick={() => setDataModalOpen(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        toast.success("Data saved successfully")
                                        setDataModalOpen(false)
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </DataModal>
                </div>

                {/* Custom Sheet Example */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Sheet</Heading>
                    <Button variant="primary" onClick={() => setSheetOpen(true)}>
                        Open Sheet
                    </Button>

                    <DialogPrimitive.Root open={sheetOpen} onOpenChange={setSheetOpen}>
                        <AnimatePresence>
                            {sheetOpen && (
                                <DialogPrimitive.Portal forceMount>
                                    <DialogPrimitive.Overlay className="fixed inset-0 z-50 bg-basic/50 backdrop-blur-sm" />
                                    <DialogPrimitive.Content className="fixed inset-y-0 right-0 z-50 h-full border-l shadow-lg focus:outline-none">
                                        <motion.div
                                            className="h-full w-[320px] bg-basic"
                                            initial={{ x: "100%" }}
                                            animate={{ x: 0 }}
                                            exit={{ x: "100%" }}
                                            transition={{ ease: "circOut" }}
                                        >
                                            <div className="flex h-full flex-col">
                                                <div className="flex items-center justify-between border-b p-4">
                                                    <DialogPrimitive.Title className="text-lg font-medium">
                                                        Sheet Title
                                                    </DialogPrimitive.Title>
                                                    <DialogPrimitive.Close asChild>
                                                        <Button variant="tertiary" className="p-1">
                                                            <i className="ri-close-line text-[1.5rem]"></i>
                                                        </Button>
                                                    </DialogPrimitive.Close>
                                                </div>
                                                <Scrollable>
                                                    <div className="p-4">
                                                        <Text variant="body2">
                                                            Sheet content goes here. This is a sliding panel that
                                                            appears from the side of the screen.
                                                        </Text>
                                                        <div className="mt-4">
                                                            <Form.Root>
                                                                <Input
                                                                    name="sheet-input"
                                                                    label="Example Input"
                                                                    placeholder="Enter text..."
                                                                />
                                                            </Form.Root>
                                                        </div>
                                                    </div>
                                                </Scrollable>
                                                <div className="border-t p-4">
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => setSheetOpen(false)}
                                                        className="w-full"
                                                    >
                                                        Close Sheet
                                                    </Button>
                                                </div>
                                            </div>
                                        </motion.div>
                                    </DialogPrimitive.Content>
                                </DialogPrimitive.Portal>
                            )}
                        </AnimatePresence>
                    </DialogPrimitive.Root>
                </div>

                {/* Content Offset */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Content Offset</Heading>
                    <ContentOffset offsetAppbar={true}>
                        <div className="border-gray-200 rounded-lg border p-4">
                            <Text variant="body2">Content with offset</Text>
                        </div>
                    </ContentOffset>
                </div>

                {/* Certificate Generation Box */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Certificate Generation Box</Heading>
                    <div className="border-gray-100 rounded border bg-basic-accent p-4">
                        {/* Mock version that shows the style without requiring all dependencies */}
                        <div className="flex max-w-md flex-col gap-4">
                            <Heading level="h4">Energy Performance Certificate</Heading>
                            <ProgressBar value={65} max={100} />
                            <Text variant="body2">Project completion: 65%</Text>
                            <div className="mt-4 flex gap-2">
                                <Button
                                    variant="secondary"
                                    onClick={() => toast.info("Would generate preview certificate")}
                                >
                                    Preview
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => toast.info("Would start certificate generation process")}
                                >
                                    Generate (€45.00)
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Payment Methods */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Payment Methods</Heading>
                    <PaymentMethods methods={mockPaymentMethods} />
                </div>

                {/* Chat Input Files */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Chat Input Files</Heading>
                    <ChatInputFiles files={[new File([], "test.pdf")]} />
                </div>

                {/* Chat Input Select */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Chat Input Select</Heading>
                    <ChatInputSelect
                        options={[
                            { label: "Option 1", value: "option1" },
                            { label: "Option 2", value: "option2" },
                            { label: "Option 3", value: "option3" },
                        ]}
                    />
                </div>

                {/* Chat Mode Button */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Chat Mode Button</Heading>
                    <ChatModeButton mode={ChatMode.offScript} onClick={() => {}} />
                </div>

                {/* ChatProject Select */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">ChatProject Select</Heading>
                    <ChatProjectSelect />
                </div>

                {/* Context Menu */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Context Menu</Heading>
                    <ContextMenu />
                </div>

                {/* Dialog */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Dialog</Heading>
                    <div className="relative h-full min-h-40 w-full overflow-hidden">
                        <Dialog onClose={() => {}} title="Dialog Title" buttonText="Close" />
                    </div>
                </div>

                {/* Project List */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Project List</Heading>
                    <ProjectList
                        projects={[]}
                        selectedId={0}
                        onAdd={function (): Promise<void> {
                            toast.success("Project added")
                            return Promise.resolve()
                        }}
                    />
                </div>

                <div></div>

                {/* Overlay */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Overlay</Heading>
                    <div className="relative aspect-square h-full w-full overflow-hidden">
                        <Overlay isOpen={true}>
                            <Text variant="body2">Overlay content</Text>
                        </Overlay>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Molecules
