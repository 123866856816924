import { z } from "zod"

/**
 * DISCLAIMER
 * This request will only contain values directly stored in the database
 * Any derived values will not be included. Check the database excel table to see what is what
 */


export const WGVDatenSchema = z.object({
    Flaechenermittlung_AN_aus_Wohnflaeche: z.boolean().nullish(),
    Keller_beheizt: z.boolean().nullish(),
    Wohnflaeche: z.number().nullish(),
    Warmwasserbereitstellung: z.string().nullish(),
}).nullable()


export type IWGVDatens = z.infer<typeof WGVDatenSchema>
