import { useHeight } from "@hooks/useHeight"
import { cls } from "@utils"
import { AnimatePresence, motion } from "framer-motion"

type ChatSelectProps = {
    options: {
        label: string | React.ReactNode
        value: string
    }[]
    highlighted?: string
    onSelect?: (value: string) => void
    onHover?: (value: string) => void
    disabled?: boolean
}

export function ChatInputSelect(props: ChatSelectProps) {
    const { options, highlighted, onHover, onSelect, disabled } = props
    const { height } = useHeight((options?.length ?? 0) > 0)

    if (disabled) {
        return null
    }

    return (
        <AnimatePresence initial={false}>
            {options.length > 0 && (
                <motion.div
                    key={"address-block"}
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height, opacity: 1, translateZ: 0 }}
                    exit={{ height: 0, opacity: 0 }}
                    className="flex max-h-[50vh] flex-col overflow-hidden overflow-y-auto overscroll-contain lg:max-h-max"
                    data-testid="chat-address-list"
                >
                    <AnimatePresence initial={false}>
                        {[...options].reverse().map((option) => {
                            return (
                                <motion.div
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: "auto", translateZ: 0 }}
                                    exit={{ opacity: 0, height: 0, padding: 0 }}
                                    transition={{ duration: 0.15 }}
                                    key={option.value}
                                    onMouseEnter={() => onHover?.(option.value)}
                                    onClick={() => onSelect?.(option.value)}
                                    className={cls(
                                        "cursor-pointer border-b-[1px] border-b-basic-accent px-5 py-2 text-basic-content-light",
                                        "cursor-pointer border-b-[1px] border-b-basic-accent px-5 py-2 text-basic-content-light",
                                        {
                                            "bg-primary text-primary-content": highlighted === option.value,
                                        }
                                    )}
                                >
                                    {option.label}
                                </motion.div>
                            )
                        })}
                    </AnimatePresence>
                </motion.div>
            )}
        </AnimatePresence>
    )
}
