import { handleFetch } from "@api/apiConfig"
import config from "@config"
import { useQuery } from "@tanstack/react-query"
import { SearchResponseSchema } from "./schema"

type IUseForwardSearch = {
    enabled?: boolean
}

export function useForwardSearch(search?: string, options?: IUseForwardSearch) {
    const { enabled = true } = options ?? {}

    return useQuery({
        queryKey: ["forward-search", search],
        queryFn: async () => {
            const url = new URL(`https://api.mapbox.com/search/geocode/v6/forward`)
            url.searchParams.set("access_token", config.VITE_MAPBOX_ACCESS_TOKEN)
            url.searchParams.set("q", search ?? "")
            url.searchParams.set("autocomplete", "true")
            url.searchParams.set("country", "DE")
            url.searchParams.set("language", "de")
            url.searchParams.set("proximity", "ip")
            url.searchParams.set("types", "address")

            const data = await handleFetch(() => fetch(url))
            const searchResponse = SearchResponseSchema.parse(data)
            const { features } = searchResponse

            // check if exact match exists and return directly if true
            const exactMatch = features.find((feature) => feature.properties.match_code?.confidence === "exact")
            if (exactMatch) {
                return [exactMatch]
            }

            // if we have high confidence matches, only return those
            const highlyLikelyMatches = features.filter(
                (feature) => feature.properties.match_code?.confidence === "high"
            )
            if (highlyLikelyMatches.length > 0) {
                return highlyLikelyMatches
            }

            // return all
            return searchResponse.features
        },
        enabled: enabled && !!search,
        placeholderData: (prev) => {
            if (!search) {
                return undefined
            }

            return prev
        },
    })
}
