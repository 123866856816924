import { apiUrl, handleFetch } from "@api/apiConfig"
import { OrderSchema, OrderStatus } from "@energuide/shared-payments"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

export type IUseOrder = {
    enabled?: boolean
}

export function usePaidOrders(params?: IUseOrder) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["orders", OrderStatus.paid],
        queryFn: async () => {
            const response = await handleFetch(async () =>
                fetch(
                    `${apiUrl}/order/by-status?status[0]=${OrderStatus.paid}&status[1]=${OrderStatus.failedAfterPaid}`,
                    {
                        method: "GET",
                        headers: {
                            ...authHeader,
                        },
                    }
                )
            )

            if (!response) {
                return null
            }

            return OrderSchema.array().parse(response)
        },
        enabled: !!enabled && !!authHeader,
    })
}
