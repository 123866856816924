import { apiUrl, handleFetch } from "@api/apiConfig"
import { ProductId, OrderSchema, IOrder } from "@energuide/shared-payments"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

export type IUseOrder = {
    enabled?: boolean
}

export function useOrderWith(projectId: number | null, productId: ProductId, params?: IUseOrder) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["order", productId, projectId],
        queryFn: async (): Promise<IOrder | null> => {
            try {
                const response = await handleFetch(async () => {
                    const response = await fetch(
                        `${apiUrl}/order/find-by?productId=${productId}&projectId=${projectId}`,
                        {
                            method: "GET",
                            headers: {
                                ...authHeader,
                            },
                        }
                    )

                    // don't show error when no entry found
                    if (response.status === 404) {
                        return null
                    }

                    return response
                })

                if (!response) {
                    return null
                }

                return OrderSchema.parse(response)
            } catch (e) {
                console.warn("outer error ", e)
                return null
            }
        },
        refetchInterval: (query) => {
            const { data } = query.state
            const shouldRefetch = !!data && !data.stripeSessionId
            return shouldRefetch ? 2000 : false
        },
        enabled: !!enabled && !!authHeader && !!projectId,
    })
}
