import { cls } from "@utils"
import React, { PropsWithChildren } from "react"
import { variants, Radius, LoadingDots, Variant } from "."

export type IButton = React.ButtonHTMLAttributes<HTMLButtonElement> &
    PropsWithChildren<{
        variant: Variant
        loading?: boolean
        radius?: keyof typeof Radius
    }>

export const Button = React.forwardRef<HTMLButtonElement, IButton>((props, ref) => {
    const { children, className, loading, variant, radius, disabled, ...rest } = props

    return (
        <button
            {...rest}
            disabled={disabled}
            ref={ref}
            className={cls(variants[variant], className, radius ? Radius[radius] : "rounded-[10px]")}
        >
            {loading ? <LoadingDots /> : children}
        </button>
    )
})
