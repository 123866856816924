import { useMobile } from "@hooks/useMobile"
import {
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    SortingState,
    getPaginationRowModel,
    getFilteredRowModel,
    ColumnDef,
    Table,
    RowSelectionState,
    OnChangeFn,
    Row,
} from "@tanstack/react-table"
import { useState } from "react"
import { DesktopView } from "./parts/desktop-view"
import { FilterPopover } from "./parts/filter-popover"
import { MobileView } from "./parts/mobile-view"

type ITable<TData, TValue> = {
    columns: ColumnDef<TData, TValue>[]
    data: TData[]
    filters?: (table: Table<TData>) => JSX.Element
    hasSearch?: boolean
    rowSelection?: RowSelectionState
    setRowSelection?: OnChangeFn<RowSelectionState>
    onRowClick?: (row: Row<TData>) => void
}

export function DataTable<TData, TValue>(props: ITable<TData, TValue>) {
    const { data, columns, filters, hasSearch = true, rowSelection, setRowSelection, onRowClick } = props
    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = useState("")
    const isMobile = useMobile()

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            globalFilter,
            rowSelection,
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onRowSelectionChange: setRowSelection,
        enableRowSelection: true,
    })

    return (
        <div className="mx-auto max-w-7xl">
            <div className="flex flex-col gap-4 overflow-hidden rounded-lg">
                <div className="md:px-8">
                    <div className="mt-4 flex w-full items-center justify-between">
                        <div className="flex items-center gap-2">
                            {hasSearch && (
                                <div className="relative">
                                    <i className="ri-search-line absolute left-3 top-1/2 -translate-y-1/2 transform text-basic-content-light" />
                                    <input
                                        type="text"
                                        value={globalFilter ?? ""}
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                        className="w-full rounded-lg border border-basic-accent bg-primary-content py-1 pl-10 pr-4 text-basic-content-light
                                            placeholder:text-basic-content-light md:max-w-[260px]"
                                        placeholder="Suchen"
                                    />
                                </div>
                            )}
                            {(isMobile || filters) && <FilterPopover table={table} filters={filters} />}
                        </div>
                        {rowSelection && (
                            <input
                                type="checkbox"
                                checked={table.getIsAllRowsSelected()}
                                onChange={table.getToggleAllRowsSelectedHandler()}
                                className="border-textVeryLight accent-textVeryLight checked:accent-textLight mr-4 size-3 cursor-pointer appearance-none rounded-sm
                                    border p-1.5 checked:appearance-auto md:hidden"
                            />
                        )}
                    </div>
                </div>
                <DesktopView table={table} rowSelection={!!rowSelection} onRowClick={onRowClick} />
                {isMobile && <MobileView table={table} rowSelection={!!rowSelection} onRowClick={onRowClick} />}
            </div>
        </div>
    )
}
