import { useAppState } from "@hooks/useState"
import { PropsWithChildren } from "react"
import { PuffLoader } from "react-spinners"

function LoadingProvider(props: PropsWithChildren<unknown>) {
    const { children } = props
    const isLoading = useAppState((state) => state.isLoading)

    return (
        <>
            {isLoading && (
                <div className="absolute z-50 flex h-screen w-screen items-center justify-center bg-basic/30">
                    <PuffLoader color="#ffffff" size={80} />
                </div>
            )}
            {children}
        </>
    )
}
export { LoadingProvider }
