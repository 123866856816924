import { loadStripe, Stripe } from "@stripe/stripe-js"

const stripeInstances: Record<string, Stripe | null> = {}

// Stripe initialization hook
export const useStripe = (pk: string) => {
    const initializeStripe = async () => {
        if (!stripeInstances[pk]) {
            stripeInstances[pk] = await loadStripe(pk)
        }
        return stripeInstances[pk]
    }

    const redirectToCheckout = async (sessionId: string) => {
        const stripe = await initializeStripe()
        if (!stripe) {
            throw new Error("Stripe failed to initialize.")
        }

        await stripe.redirectToCheckout({
            sessionId,
        })
    }

    return {
        redirectToCheckout,
    }
}
