import * as Progress from "@radix-ui/react-progress"
import { cls } from "@utils"
import { useMemo } from "react"

type ICorner = {
    className?: string
}

function Corner(props: ICorner) {
    const { className } = props
    return (
        <div className={cls("h-[6px] w-[6px] border-l-[1px] border-t-[1px] border-primary-accent/50", className)}></div>
    )
}

export type IProgressBar = {
    value: number
    max: number
}

export function ProgressBar(props: IProgressBar) {
    const { value, max } = props
    const ratio = useMemo(() => (max === 0 ? 0 : value / max), [value, max])

    return (
        <div className="relative p-2">
            <Corner className="absolute left-0 top-0" />
            <Corner className="absolute right-0 top-0 scale-x-[-1]" />
            <Corner className="absolute bottom-0 right-0 scale-[-1]" />
            <Corner className="absolute bottom-0 left-0 scale-y-[-1]" />

            <Progress.Root
                className="relative h-2 overflow-clip rounded-full bg-basic-accent"
                value={value}
                max={max || 1} // 0 is not a valid prop value
            >
                <Progress.Indicator
                    className="h-2 rounded-full bg-primary"
                    style={{ transform: `translateX(-${(1 - ratio) * 100}%)` }}
                />
            </Progress.Root>
        </div>
    )
}

export type IProgressIndicator = {
    value: number
}

export function ProgressIndicator(props: IProgressIndicator) {
    const { value } = props

    const strokeDashoffset = 100 - value

    return (
        <div className="relative size-5 p-0.5">
            <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                <circle
                    cx="18"
                    cy="18"
                    r="16"
                    fill="none"
                    className="stroke-border-light text-border-light"
                    strokeWidth="4"
                ></circle>
                <circle
                    cx="18"
                    cy="18"
                    r="16"
                    fill="none"
                    className="stroke-black-white text-black-white"
                    strokeWidth="4"
                    strokeDasharray="100"
                    strokeDashoffset={strokeDashoffset}
                ></circle>
            </svg>
        </div>
    )
}
