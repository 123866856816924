import { apiUrl, handleFetch } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"

export function useCreateOrder(projectId: number | null, productId: number | null) {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async () => {
            if (!authHeader || !projectId) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/order`, {
                    method: "post",
                    body: JSON.stringify({
                        projectId,
                        productId,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                })
            )
        },
    })
}
