import { useEffect } from "react"

/**
 * ThemeInitializer - A component that handles theme initialization without UI
 * This component should be placed high in the component tree to ensure it runs early
 */
export const ThemeInitializer = () => {
    useEffect(() => {
        // Check if theme class is already applied
        const hasThemeClass =
            document.documentElement.classList.contains("dark") || document.documentElement.classList.contains("light")

        if (!hasThemeClass) {
            // Check for saved preference
            const savedTheme = localStorage.getItem("theme")
            if (savedTheme) {
                document.documentElement.classList.add(savedTheme)
            } else {
                // If no saved preference, use system preference
                const systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches
                document.documentElement.classList.add(systemDark ? "dark" : "light")
            }
        }

        // Listen for system theme changes when no user preference is set
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)")
        const listener = (e: MediaQueryListEvent) => {
            if (!localStorage.getItem("theme")) {
                document.documentElement.classList.remove("dark", "light")
                document.documentElement.classList.add(e.matches ? "dark" : "light")
            }
        }
        mediaQuery.addEventListener("change", listener)
        return () => mediaQuery.removeEventListener("change", listener)
    }, [])

    // This component doesn't render anything
    return null
}

export default ThemeInitializer
