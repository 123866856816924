import { useEditChatMessage } from "@api/chat/useEditChatMessage"
import { Button } from "@components/atoms/buttons"
import { Input } from "@components/atoms/input"
import { icons } from "@components/organisms/changeable-field"
import { ChatInputChoice } from "@components/organisms/chat-inputs/chat-input-choice"
import { DataModal } from "@components/organisms/data-modal"
import * as Form from "@radix-ui/react-form"
import React from "react"

interface Props {
    chatId: number
    projectId: number
    defaultAnswer?: string
    choices?: string[]
    customTrigger?: React.ReactNode
}

export function EditAnswer(props: Props) {
    const { chatId, projectId, defaultAnswer, choices, customTrigger } = props
    const [open, setOpen] = React.useState(false)
    const changeOpen = (v: boolean) => {
        setOpen(v)
        if (v) {
            setAnswer(defaultAnswer || "")
        } else {
            setAnswer("")
        }
    }
    const { mutateAsync } = useEditChatMessage(projectId)

    const [answer, setAnswer] = React.useState(defaultAnswer || "")

    const onEdit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()

        const formData = new FormData(event.currentTarget)
        const answer = formData.get("edit-answer-input") as string
        if (typeof answer === "string") {
            const response = await mutateAsync({ answer, chatId })
            if (response) {
                setOpen(false)
            }
        }
    }

    const onChoiceSelected = async (choice: string) => {
        if (typeof answer === "string") {
            const response = await mutateAsync({ answer: choice, chatId })
            if (response) {
                setOpen(false)
            }
        }
    }

    return (
        <DataModal
            trigger={
                customTrigger ?? <i className="ri-edit-line text-basic-content-light cursor-pointer" onClick={() => null}></i>
            }
            open={open}
            onOpenChange={changeOpen}
            iconClass={icons["edit"]}
            title="Antwort bearbeiten"
            description=""
        >
            <Form.Root onSubmit={onEdit} className="grid max-h-[50vh] gap-4">
                {!choices && (
                    <>
                        <Input
                            label="Antwort bearbeiten"
                            name="edit-answer-input"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                        />
                        <Form.Submit asChild className="border border-black px-8 py-2">
                            <Button variant="primary">Speichern</Button>
                        </Form.Submit>
                    </>
                )}
                {choices ? (
                    <ChatInputChoice
                        message={answer}
                        onMessageChanged={setAnswer}
                        choices={choices}
                        onSelectionChanged={onChoiceSelected}
                        disabled={false}
                        displayMode="list"
                        preSelected={answer}
                    />
                ) : null}
            </Form.Root>
        </DataModal>
    )
}
