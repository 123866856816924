import { Text } from "@components/atoms/typography"

type ISmallStat = {
    name: string
    value: string
}

export function SmallStat(props: ISmallStat) {
    const { name, value } = props
    return (
        <div className="grid grid-cols-2">
            <Text variant="strong2" className="text-basic-content">
                {name}
            </Text>
            <Text variant="body2" className="text-basic-content justify-self-end">
                {value}
            </Text>
        </div>
    )
}
