import { Ref } from "@energuide/shared-common"
import { z } from "zod"

export const projectIdPattern = "[0-9]+"

export const ProjectSchema = z.object({
    id: z.number(),
    name: z.string(),
    project_authorization: Ref(z.any()),
    chats: Ref(),
    energy_certificates: Ref(),
    GDaten: Ref(),
    GEGDaten: Ref(),
    sBuilding: Ref(),
})
