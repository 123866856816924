import * as ContextMenu from "@radix-ui/react-context-menu"
import { cls } from "@utils"
import { RoomContextMenu } from "."

export interface IListItem {
    title: string
    value: string
    onDelete?: () => void
    onEdit?: () => void
    onClick?: () => void
    className?: string
    icon?: React.ReactNode
}

export function ListItem({ title, value, onDelete, onEdit, onClick, className, icon }: IListItem) {
    return (
        <ContextMenu.Root>
            <ContextMenu.Trigger>
                <div
                    onClick={onClick}
                    className={cls(
                        "flex w-full select-none items-center justify-between rounded-xl bg-basic p-4 font-label shadow-md",
                        className
                    )}
                >
                    <div className="inline-block">
                        <p className="text-tiny font-normal text-basic-content">{title}:</p>
                        <p className="text-body2 text-basic-content">{value}</p>
                    </div>
                    <div onClick={onEdit} className="cursor-pointer text-black-white">
                        {icon}
                    </div>
                </div>
            </ContextMenu.Trigger>

            <RoomContextMenu onDelete={onDelete} onEdit={onEdit} />
        </ContextMenu.Root>
    )
}
