import { Heading, Text } from "@components/atoms/typography"
import { toast } from "sonner"

const Advanced = () => {
    // Mock data for complex components using proper types
    const mockProjects = [
        { id: 1, name: "Residential Building A" },
        { id: 2, name: "Commercial Building B" },
        { id: 3, name: "Office Complex C" },
    ]
    return (
        <div>
            {" "}
            {/* Additional Components Heading */}
            <div className="mb-6">
                <Heading level="h2">Advanced Components</Heading>
                <Text variant="body1">Components requiring complex data and context</Text>
            </div>
            {/* Advanced components displayed in a grid */}
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                {/* Chat Project Select */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Chat Project Select</Heading>
                    <Text variant="body2">
                        This component requires router context. Below is a mockup of its appearance:
                    </Text>
                    <div className="border-gray-100 rounded border bg-basic-accent p-4">
                        {/* Mock version that shows the style without requiring router context */}
                        <div>
                            <p className="text-bodyStrong">Kontext</p>
                            <div
                                className="relative flex items-center justify-items-start gap-1 py-2 text-bodyRegular"
                                onClick={() => toast.info("Would open project selector")}
                            >
                                <p>Liegenschaften {">"}</p>
                                <span>{mockProjects[0].name}</span>
                                <i className="ri-arrow-down-s-line ml-1"></i>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Initial Project Loader */}
                <div className="flex flex-col gap-4 rounded-lg bg-basic/50 p-4">
                    <Heading level="h3">Initial Project Loader</Heading>
                    <Text variant="body2">
                        This component has no UI and handles automatic project loading and navigation.
                    </Text>
                    <div className="border-gray-100 rounded border bg-basic-accent p-4">
                        <div className="flex items-center gap-2">
                            <i className="ri-loader-2-line text-2xl animate-spin text-primary"></i>
                            <Text variant="body2">InitialProjectLoader is mounted but doesn't render any UI.</Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Advanced
