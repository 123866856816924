import { RegisterSchemaType } from "@api/auth/register"
import { Button } from "@components/atoms/buttons"
import { EnerGuide } from "@components/atoms/energuide"
import { Heading } from "@components/atoms/typography"
import { ACCOUNT_TYPES } from "@energuide/shared-user"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { motion } from "framer-motion"

interface Props {
    onNext: (d: Partial<RegisterSchemaType>) => void
    onPrev: () => void
}

export function AccountTypePage(props: Props) {
    const { onNext, onPrev } = props

    useTitlebar({
        title: "",
        mode: "callback",
        showContextMenu: false,
        onClick: onPrev,
    })

    async function submit(type: "private" | "company") {
        if (type === "private") {
            onNext({ type: ACCOUNT_TYPES.Private.id })
        } else {
            onNext({ type: ACCOUNT_TYPES.Enterprise.id })
        }
    }

    return (
        <>
            <EnerGuide
                glow
                className="absolute inset-0 z-[-1] grid overflow-hidden"
                energuideClassName="absolute top-[-60%] lg:top-[-22%] z-[-1] aspect-square justify-self-center w-[18rem] sm:w-[20rem] md:w-[30rem] lg:w-[22rem] xl:w-[25rem] 2xl:w-[40rem]"
                layoutId="energuide"
            />

            <div className="mx-auto grid min-h-full w-full max-w-screen-sm content-center gap-12 pt-2">
                <div className="grid gap-12 rounded-lg bg-basic-strong px-8 py-8 shadow-lg backdrop-blur-2xl">
                    <motion.div className="grid gap-6">
                        <Heading level="h1" className="text-center">
                            Wähle deinen Account-Typ
                        </Heading>
                        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6">
                            <Button
                                variant="primary"
                                onClick={() => submit("private")}
                                data-testid="private-account-type-button"
                            >
                                Privat
                                <i className="ri-home-fill"></i>
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => submit("company")}
                                data-testid="enterprise-account-type-button"
                            >
                                Unternehmen
                                <i className="ri-building-fill"></i>
                            </Button>
                        </div>
                    </motion.div>
                </div>
            </div>
        </>
    )
}
