import { useUser } from "@api/user/useUser"
import { RoleTypes } from "@energuide/shared-user"
import { ReactNode } from "react"
import { Navigate } from "react-router-dom"

interface AdminRouteProps {
    children: ReactNode
    redirectTo?: string
}

/**
 * A wrapper component that only renders its children if the user has admin role
 * Otherwise redirects to the specified path
 */
export function AdminRoute({ children, redirectTo = "/authenticated/chat" }: AdminRouteProps) {
    const { data: user, isLoading } = useUser()
    const isAdmin = user?.role?.type === RoleTypes.ADMIN

    // Show nothing while loading to prevent flickering
    if (isLoading) {
        return null
    }

    // Redirect non-admin users
    if (!isAdmin) {
        return <Navigate to={redirectTo} />
    }

    // Render children for admin users
    return <>{children}</>
}
