import { flexRender, Row, Table } from "@tanstack/react-table"

type IMobileView<TData> = {
    table: Table<TData>
    rowSelection: boolean
    onRowClick?: (row: Row<TData>) => void
}

export function MobileView<TData>(props: IMobileView<TData>) {
    const { table, rowSelection, onRowClick } = props
    return (
        <div className="md:hidden">
            <div className="flex flex-col gap-2 divide-y-2 divide-border-light">
                {table.getRowModel().rows.map((row) => (
                    <div
                        key={row.id}
                        className="cursor-pointer space-y-4 p-4"
                        onClick={() => onRowClick?.(row)}
                        data-testid="table-row"
                    >
                        {rowSelection && (
                            <div className="flex items-center justify-end">
                                <input
                                    type="checkbox"
                                    checked={row.getIsSelected()}
                                    onChange={row.getToggleSelectedHandler()}
                                    onClick={(e) => e.stopPropagation()}
                                    className="accent-textVeryLight checked:accent-textLight size-3 cursor-pointer appearance-none rounded-sm border p-1.5
                                        checked:appearance-auto"
                                />
                            </div>
                        )}
                        {row.getVisibleCells().map((cell) => {
                            const headerContext = table
                                .getHeaderGroups()[0]
                                .headers.find((header) => header.id === cell.column.id)

                            return (
                                <tr key={cell.id} className="flex items-center justify-between">
                                    <div className={"flex select-none items-center gap-1 text-bodyStrong"}>
                                        {headerContext &&
                                            flexRender(cell.column.columnDef.header, headerContext.getContext())}
                                    </div>
                                    <div className="text-bodyRegular">
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                </tr>
                            )
                        })}
                    </div>
                ))}
            </div>
        </div>
    )
}
