import { Text } from "@components/atoms/typography.tsx"
import { cls } from "@utils"

type Methods = {
    iconClass: string
    name: string
    ending: string
}

type IPaymentMethods = {
    methods: Methods[]
}

export function PaymentMethods(props: IPaymentMethods) {
    const { methods } = props
    return (
        <div className="grid gap-4">
            <header className="grid grid-cols-[1fr_1fr_auto] gap-8">
                <Text variant="strong2" className="text-basic-content">
                    Zahlungsmittel
                </Text>
                <Text variant="strong2" className="text-basic-content justify-self-end">
                    Endung
                </Text>
                <i className="ri-delete-bin-fill text-basic-content text-[1.5rem] leading-none"></i>
            </header>
            <div className="grid gap-3">
                {methods.map(({ iconClass, name, ending }, idx) => (
                    <div className="grid grid-cols-[1fr_1fr_auto] gap-8" key={idx}>
                        <div className="grid grid-cols-[auto_1fr] gap-6">
                            <i className={cls(iconClass, "text-basic-content text-[1.5rem] leading-none")}></i>
                            <Text variant="strong2" className="text-basic-content">
                                {name}
                            </Text>
                        </div>
                        <Text variant="body2" className="text-basic-content justify-self-end">
                            ...{ending}
                        </Text>
                        <i className="ri-delete-bin-fill text-basic-content-light text-[1.5rem] leading-none"></i>
                    </div>
                ))}
                <div className="grid grid-cols-[auto_1fr] gap-6">
                    <i className={"ri-add-line text-basic-content-light text-[1.5rem] leading-none"}></i>
                    <Text variant="strong2" className=" text-basic-content-light">
                        Hinzufügen
                    </Text>
                </div>
            </div>
        </div>
    )
}
