import { IChat, ISender } from "@api/chat/schema.ts"
import { useUser } from "@api/user/useUser"
import { LoadingDots } from "@components/atoms/buttons"
import { EnerGuide } from "@components/atoms/energuide"
import { MarkdownInterpreter } from "@components/atoms/markdown-interpreter"
import { Text } from "@components/atoms/typography.tsx"
import { UserAvatar } from "@components/molecules/avatar.tsx"
import { PreviewImage } from "@components/molecules/preview-image"
import { ChatCards } from "@components/organisms/chat-cards"
import { EditAnswer } from "@components/organisms/edit-answer"
import { IntentType } from "@energuide/shared-project"
import { AnimatePresence, motion } from "framer-motion"
import { DateTime } from "luxon"
import { useMemo, useEffect } from "react"

type IChatMessages = {
    chats: IChat[]
    projectId: number
    onChoiceSelected?: (message: string) => void
    onNewChoices?: (choices: string[]) => void
}

export function ChatMessages(props: IChatMessages) {
    const { chats, onChoiceSelected, onNewChoices, projectId } = props

    // Effect to handle new choices
    useEffect(() => {
        const lastMessage = chats[chats.length - 1]
        if (lastMessage?.choices && onNewChoices) {
            onNewChoices(lastMessage.choices)
        }
    }, [chats, onNewChoices])

    return (
        <AnimatePresence initial={false}>
            {chats.map((chat, idx) => {
                const { id, sender, intent_type, display, displayKey } = chat

                if (intent_type === "init_data_collection") {
                    return null
                }

                if (display === "cards") {
                    const hintClick = (message: string) => onChoiceSelected && onChoiceSelected(message)
                    const hints = chat.choices?.map((c, i) => ({ id: i, question: c })) ?? []

                    return (
                        <motion.div
                            key={`${displayKey}-${id}`}
                            id={`message-${id}`}
                            className="chat-message grid grid-cols-[auto_1fr] gap-4"
                            initial={{ opacity: 0, y: "50%" }}
                            animate={{ opacity: 1, y: "0%" }}
                        >
                            <ChatAvatar sender={sender} />
                            <ChatContent chat={chat} previousChat={chats.at(idx - 1) ?? null} projectId={projectId} />
                            {hints && idx === chats.length - 1 ? (
                                <ChatCards
                                    hints={hints}
                                    onClick={hintClick}
                                    disabled={false}
                                    className="col-start-2 py-4"
                                />
                            ) : null}
                        </motion.div>
                    )
                }

                return (
                    <motion.div
                        key={displayKey}
                        id={`message-${displayKey}`}
                        className="chat-message grid grid-cols-[auto_1fr] gap-4"
                        initial={{ opacity: 0, y: "50px" }}
                        animate={{ opacity: 1, y: "0%" }}
                    >
                        <ChatAvatar sender={sender} />
                        <ChatContent chat={chat} previousChat={chats.at(idx - 1) ?? null} projectId={projectId} />
                    </motion.div>
                )
            })}
        </AnimatePresence>
    )
}

type IChatAvatar = {
    sender?: ISender | null
}

function ChatAvatar(props: IChatAvatar) {
    const { sender } = props
    const { data: user } = useUser()

    if (!sender) {
        return <EnerGuide energuideClassName="h-12 w-12" />
    }

    return <UserAvatar src={user?.avatar?.url ?? ""} alt="" initials={"You"} variant="chat" />
}

type IChatContent = {
    chat: IChat
    previousChat: IChat | null
    projectId: number
}

function ChatContent(props: IChatContent) {
    const { chat, projectId, previousChat } = props
    const { sender, lastUpdate, content, role, id, files = [] } = chat
    const hasFiles = files.length > 0

    const canEdit = useMemo(() => {
        const blackListIntents: IntentType[] = ["answer_add_task", "answer_address_location"]
        const isDefaultIntent = !chat.intent_type
        const isBlacklisted = chat.intent_type && blackListIntents.includes(chat.intent_type)
        if (role === "user" && (isDefaultIntent || !isBlacklisted)) {
            return true
        } else {
            return false
        }
    }, [chat, role])

    const isChoiceSelection = useMemo(() => {
        if (role !== "user" || !content || !previousChat?.choices) {
            return false
        }
        return previousChat.choices.includes(content)
    }, [content, previousChat?.choices, role])

    return (
        <div className="grid gap-1">
            <div className="grid grid-cols-[minmax(0,1fr)_auto] items-center justify-between gap-1 ">
                <Text variant="strong2" className="overflow-clip text-ellipsis text-basic-content">
                    {sender ? sender.displayName : "EnerGuide"}
                </Text>
                <div className="flex gap-4">
                    <Text variant="tiny" className="text-basic-content-light">
                        {lastUpdate ? DateTime.fromJSDate(lastUpdate).toFormat("HH:mm") : null}
                    </Text>
                </div>
            </div>
            {!content && !hasFiles ? (
                <div className="grid h-6 items-center">
                    <LoadingDots />
                </div>
            ) : (
                <>
                    {isChoiceSelection && canEdit ? (
                        <EditAnswer
                            chatId={id}
                            projectId={projectId}
                            defaultAnswer={content ?? undefined}
                            choices={previousChat?.choices ?? undefined}
                            customTrigger={
                                <div
                                    className="w-max cursor-pointer whitespace-pre-line rounded-lg bg-basic-accent px-3 py-2 hover:scale-[1.02]"
                                    style={{ wordBreak: "break-word" }}
                                    title="Auswahl bearbeiten"
                                >
                                    <i className="ri-edit-line pr-2 " />
                                    <MarkdownInterpreter content={content ?? ""} />
                                </div>
                            }
                        />
                    ) : (
                        <div className="whitespace-pre-line" style={{ wordBreak: "break-word" }}>
                            <MarkdownInterpreter content={content ?? ""} />
                        </div>
                    )}
                    {Boolean(files?.length) && (
                        <div className="flex flex-wrap gap-2">
                            {files.map((file) => (
                                <PreviewImage
                                    key={file.url}
                                    path={file.url ?? ""}
                                    alt="Hochgeladenes Bild"
                                    allowPreview
                                    className="justify-self-start"
                                    filename={file.name ?? ""}
                                />
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
