import { PropsWithChildren } from "react"

type BadgeProps = PropsWithChildren<{
    label: string | number
    hidden: boolean
}>

export function Badge(props: BadgeProps) {
    const { label, children } = props

    return (
        <div className="relative">
            <span
                className="text-basic absolute right-0 top-0 grid h-6 w-6 translate-x-[50%] translate-y-[-50%] place-items-center rounded-full
                    bg-primary text-tiny"
            >
                {label}
            </span>
            {children}
        </div>
    )
}
