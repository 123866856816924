import { useCertificateStatus } from "@api/certificate/useCertificateStatus"
import { useCreateCertificate } from "@api/certificate/useCreateCertificate"
import { useProgress } from "@api/certificate/useProgress"
import { useCreateOrder } from "@api/order/useCreateOrder"
import { useOrderWith } from "@api/order/useOrderWith"
import { Button, LinkButton } from "@components/atoms/buttons"
import { ProgressIndicator } from "@components/atoms/progress"
import config from "@config"
import {
    CertificateStatus,
    EnergyCertificateType,
    EnergyCertificateVariant,
} from "@energuide/shared-energy-certificate"
import { OrderStatus } from "@energuide/shared-payments"
import { useIsPaidProductId } from "@hooks/useIsPaid"
import { useStripe } from "@hooks/useStripe"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export type CertificateStatusViewProps = {
    projectId: number
    type: EnergyCertificateType
    variant?: "table" | "box"
    progress?: {
        value: number
        max: number
    }
    productId?: number
    title?: string
}

export function CertificateStatusView({
    projectId,
    type,
    variant = "table",
    progress: propProgress,
    productId: propProductId,
}: CertificateStatusViewProps) {
    const navigate = useNavigate()
    const { data: progressData } = useProgress(projectId)

    let progress = propProgress
    if (!progress && progressData) {
        const certificateData =
            type === EnergyCertificateType.Consumption
                ? progressData.consumption_certificate
                : progressData.demand_certificate

        progress = {
            value: certificateData.answered,
            max: certificateData.total,
        }
    }

    const defaultProductId = type === EnergyCertificateType.Consumption ? 1 : 2
    const productId = propProductId || defaultProductId

    const { data: certificateStatus, refetch: refetchStatus } = useCertificateStatus(projectId, type)
    const { draft, final } = certificateStatus ?? {}
    const { data: orderData, refetch: refetchOrder } = useOrderWith(projectId, productId)

    const { redirectToCheckout } = useStripe(config.VITE_STRIPE_PUBLIC_KEY)
    const paidProductId = useIsPaidProductId()
    const { mutateAsync: createCertificate } = useCreateCertificate(projectId, type, EnergyCertificateVariant.Draft)
    const { mutateAsync: createOrder } = useCreateOrder(projectId, productId)

    const isDone = progress?.max ? progress.max > 0 && Math.round(progress.value) === Math.round(progress.max) : false
    const percent = progress?.max ? (progress.max > 0 ? (100 * progress.value) / progress.max : 0) : 0

    const generatePreview = async () => {
        await createCertificate()
        await refetchStatus()
    }

    const startPayment = async () => {
        await createOrder()
        await refetchOrder()
    }

    const handleDataCollectionClick = () => {
        navigate(`/authenticated/chat/${projectId}`)
    }

    useEffect(() => {
        const func = async () => {
            if (
                paidProductId !== productId &&
                orderData?.status === OrderStatus.ongoing &&
                orderData?.stripeSessionId
            ) {
                await redirectToCheckout(orderData.stripeSessionId)
            }
        }

        void func()
    }, [orderData, redirectToCheckout, projectId, productId, paidProductId])

    if (final?.status === CertificateStatus.hasFinal) {
        if (variant === "box") {
            return (
                <div className="flex justify-end">
                    <LinkButton variant="primary" href={final.url ?? ""} className="h-fit min-h-0 w-fit   " isExternal>
                        <i className="ri-article-line"></i>
                        Zertifikat ansehen
                    </LinkButton>
                </div>
            )
        }

        return (
            <div className="flex items-center gap-2">
                <i className="ri-checkbox-circle-fill text-[1.5rem] leading-none text-success" />
                <p className="text-bodyStrong text-success">
                    Gültig bis{" "}
                    {new Date(new Date().setFullYear(new Date().getFullYear() + 10)).toLocaleDateString("de-DE", {
                        month: "2-digit",
                        year: "numeric",
                    })}
                </p>
            </div>
        )
    }

    if (orderData?.status === OrderStatus.failedAfterPaid) {
        return (
            <Button variant="primary" disabled className="h-fit min-h-0 w-fit   ">
                Es gab einen Fehler, wir sind dran...
            </Button>
        )
    }

    if (orderData?.status === OrderStatus.reviewed) {
        return (
            <Button variant="primary" disabled className="h-fit min-h-0 w-fit   ">
                Finales Zertifikat wird generiert...
            </Button>
        )
    }

    if (orderData?.status === OrderStatus.paid || paidProductId === productId) {
        return (
            <Button variant="primary" disabled className="h-fit min-h-0 w-fit   ">
                Daten werden geprüft
            </Button>
        )
    }

    if (draft?.status === CertificateStatus.hasPreview) {
        const loading = orderData?.status === OrderStatus.ongoing

        if (variant === "box") {
            return (
                <div className="flex flex-col gap-2 sm:flex-row">
                    <LinkButton
                        variant="secondary"
                        className="h-fit min-h-0 w-fit   "
                        href={draft.url ?? ""}
                        isExternal
                    >
                        <i className="ri-article-line"></i>
                        Vorschau ansehen
                    </LinkButton>
                    <Button
                        variant="primary"
                        className="h-fit min-h-0 w-fit   "
                        onClick={startPayment}
                        loading={loading}
                        disabled={loading}
                    >
                        <i className="ri-shopping-cart-fill"></i>
                        Kaufen
                    </Button>
                </div>
            )
        }

        return (
            <LinkButton variant="secondary" className="h-fit min-h-0 w-fit   " href={draft.url ?? ""} isExternal>
                <i className="ri-article-line"></i>
                Vorschau ansehen
            </LinkButton>
        )
    }

    if (draft?.status === CertificateStatus.running) {
        return (
            <Button variant="primary" disabled className="h-fit min-h-0 w-fit   " loading>
                Vorschau wird erstellt
            </Button>
        )
    }

    if (draft?.status === CertificateStatus.failed) {
        return (
            <Button
                variant="primary"
                onClick={async (e) => {
                    e.stopPropagation()
                    await generatePreview()
                }}
                className="h-fit min-h-0 w-fit   "
            >
                Neue Vorschau erstellen
            </Button>
        )
    }

    if (isDone) {
        return (
            <Button
                variant="primary"
                onClick={async (e) => {
                    e.stopPropagation()
                    await generatePreview()
                }}
                className="h-fit min-h-0 w-fit   "
            >
                Vorschau erstellen
            </Button>
        )
    }

    if (variant === "box") {
        return (
            <div className="grid gap-4">
                <div className="flex justify-between">
                    <Button variant="secondary" className="h-fit min-h-0 w-fit" onClick={handleDataCollectionClick}>
                        Datenaufnahme
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div className="relative flex items-center gap-2">
            <ProgressIndicator value={percent} />
            <Button variant="tertiary" className="w-fit text-bodyStrong" onClick={handleDataCollectionClick}>
                {percent === 0 ? "Datenaufnahme starten" : "Datenaufnahme fortsetzen"}
            </Button>
        </div>
    )
}
