import { GradientBorder } from "@components/atoms/gradient"
import { Text } from "@components/atoms/typography"
import * as Label from "@radix-ui/react-label"
import * as RadixSelect from "@radix-ui/react-select"
import { cls } from "@utils"
import React from "react"

type ISelect = RadixSelect.SelectProps & {
    id: string
    label: string
    options: {
        value: string
        label: string
    }[]
}
export function Select(props: ISelect) {
    const { options, label, id, ...selectProps } = props
    const [open, setOpen] = React.useState<boolean>(false)

    const [selectedOption, setSelectedOption] = React.useState<string>(selectProps.defaultValue ?? "")
    const selectedLabel = React.useMemo(
        () => options.find((option) => option.value === selectedOption)?.label,
        [selectedOption]
    )

    return (
        <RadixSelect.Root
            {...selectProps}
            open={open}
            onOpenChange={setOpen}
            onValueChange={(value) => setSelectedOption(value)}
        >
            <div className="grid gap-2.5">
                <Label.Root htmlFor={id} className="font-label text-label text-black-white">
                    {label}
                </Label.Root>
                <RadixSelect.Trigger id={id}>
                    <GradientBorder variant="dark" radius="small">
                        <div className="grid grid-cols-[1fr_auto] gap-3 rounded-lg bg-basic-accent p-3 pl-4">
                            <RadixSelect.Value>
                                <Text variant="body2" className="grid justify-items-start text-basic-content">
                                    {selectedLabel}
                                </Text>
                            </RadixSelect.Value>
                            <RadixSelect.Icon asChild>
                                <i
                                    className={cls(
                                        {
                                            "ri-expand-up-down-line": !open,
                                            "ri-contract-up-down-line": open,
                                        },
                                        "text-[1.5rem] leading-none text-basic-content-light"
                                    )}
                                ></i>
                            </RadixSelect.Icon>
                        </div>
                    </GradientBorder>
                </RadixSelect.Trigger>
            </div>

            <RadixSelect.Content
                side="top"
                sideOffset={5}
                position="popper"
                style={{ width: "var(--radix-select-trigger-width)" }}
            >
                <GradientBorder variant="dark" radius="small">
                    <RadixSelect.Viewport className="rounded-lg bg-basic-accent">
                        {options.map((option) => {
                            return (
                                <RadixSelect.Item
                                    value={option.value}
                                    key={option.value}
                                    className="select-none px-4 py-3 text-basic-content hover:bg-primary hover:text-primary-content"
                                >
                                    <RadixSelect.ItemText>
                                        <Text variant="body2" className="text-normal">
                                            {option.label}
                                        </Text>
                                    </RadixSelect.ItemText>
                                </RadixSelect.Item>
                            )
                        })}
                    </RadixSelect.Viewport>
                </GradientBorder>
            </RadixSelect.Content>
        </RadixSelect.Root>
    )
}
