import { useProgress } from "@api/certificate/useProgress"
import { useGDaten } from "@api/g-daten/useGDaten"
import { useGEGDaten } from "@api/geg-daten/useGEGDaten"
import { useKpis } from "@api/kpis/useKpis"
import { useProject } from "@api/projects/useProject"
import { useWGVDaten } from "@api/wgv-daten/useWGVDaten"
import { Heading } from "@components/atoms/typography"
import { BigStat } from "@components/molecules/big-stat"
import { CertificateBox } from "@components/molecules/certificate-box"
import { Sheet, SheetContent } from "@components/organisms/sheet"
import { EnergyCertificateType } from "@energuide/shared-energy-certificate"
import { ProductId } from "@energuide/shared-payments"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

export function DetailOverlay() {
    const params = useParams()
    const projectId = params.projectId ? parseInt(params.projectId) : null
    const { data: project } = useProject(projectId)
    const { data: gDaten } = useGDaten(projectId)
    const { data: progress } = useProgress(projectId)
    const { data: kpis } = useKpis(projectId)
    const { data: gegDaten } = useGEGDaten(projectId)
    const { data: wgvDaten } = useWGVDaten(projectId)

    const [open, setOpen] = useState(true)
    const navigate = useNavigate()

    const onOpenChange = (open: boolean) => {
        setOpen(open)
        if (!open) {
            navigate("/authenticated/properties", { replace: true })
        }
    }

    const title = gDaten?.Gebaeudeadresse_Strasse_Nr ?? `Liegenschaft ${projectId}`
    const description = gDaten?.Gebaeudeadresse_Postleitzahl
        ? `${gDaten.Gebaeudeadresse_Postleitzahl} ${gDaten.Gebaeudeadresse_Ort}`
        : ""

    return (
        <Sheet open={open} onOpenChange={onOpenChange}>
            <SheetContent
                title={title}
                description={description}
                image={gDaten?.Gebaeude_Foto ?? ""}
                className=" w-full max-w-2xl"
            >
                <div className="flex flex-col gap-2 border-b border-basic-accent p-6">
                    <Heading level="h5" className="text-basic-content">
                        Stammdaten
                    </Heading>
                    <div className="grid grid-cols-2">
                        <p className="text-small text-basic-content-medium">Baujahr</p>
                        <p className="text-small text-basic-content-medium">{gegDaten?.Baujahr_Gebaeude ?? "-"}</p>
                    </div>
                    <div className="grid grid-cols-2">
                        <p className="text-small text-basic-content-medium">Wohnfläche</p>
                        <p className="text-small text-basic-content-medium">
                            {wgvDaten?.Wohnflaeche ? `${wgvDaten.Wohnflaeche} m²` : "-"}
                        </p>
                    </div>
                    <div className="grid grid-cols-2">
                        <p className="text-small text-basic-content-medium">Wärmeerzeuger</p>
                        <p className="text-small text-basic-content-medium">
                            {gegDaten?.Wesentliche_Energietraeger_Warmwasser ?? "-"}
                        </p>
                    </div>
                </div>
                <div className="flex flex-col gap-4 border-b border-basic-accent p-6">
                    <Heading level="h5" className="text-basic-content">
                        Energieverbrauch
                    </Heading>
                    <section className="relative grid grid-cols-2 gap-4 px-2">
                        <BigStat
                            iconClass="ri-fire-fill"
                            name="Energiebedarf"
                            value={
                                kpis?.endenergie?.toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }) ?? "-"
                            }
                            unit="kWh/(m²a)"
                        />
                        <BigStat
                            iconClass="ri-cloud-fill"
                            name="CO₂-Emissionen"
                            value={
                                kpis?.co2?.toLocaleString("de-DE", {
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3,
                                }) ?? "-"
                            }
                            unit="kg/(m²a)"
                        />
                        <BigStat
                            iconClass="ri-fire-fill"
                            name="Primärenergiebedarf"
                            value={
                                kpis?.primaerenergie?.toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }) ?? "-"
                            }
                            unit="kWh/(m²a)"
                        />
                        <BigStat
                            iconClass="ri-verified-badge-fill"
                            name="Effizienzklasse"
                            value={kpis?.effizienzklasse ?? "-"}
                            unit="nach GEG 24"
                        />
                    </section>
                </div>
                <div className="flex flex-col gap-4 border-b border-basic-accent p-6">
                    <Heading level="h5" className="text-basic-content">
                        Dokumente
                    </Heading>
                    {/* TODO: Add documents */}
                </div>
                <div className="flex flex-col gap-4 border-b border-basic-accent p-6">
                    <CertificateBox
                        title="Verbrauchsausweis"
                        progress={{
                            value: progress?.consumption_certificate.answered ?? 0,
                            max: progress?.consumption_certificate.total ?? 0,
                        }}
                        projectId={project?.id ?? 0}
                        productId={ProductId.ConsumptionCertificate}
                        type={EnergyCertificateType.Consumption}
                    />
                </div>
                <div className="flex flex-col gap-4 border-basic-accent p-6">
                    <CertificateBox
                        title="Bedarfsausweis"
                        progress={{
                            value: progress?.demand_certificate.answered ?? 0,
                            max: progress?.demand_certificate.total ?? 0,
                        }}
                        projectId={project?.id ?? 0}
                        productId={ProductId.DemandCertificate}
                        type={EnergyCertificateType.Demand}
                    />
                </div>
            </SheetContent>
        </Sheet>
    )
}
