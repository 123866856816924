import { Button } from "@components/atoms/buttons"
import { GradientBorder } from "@components/atoms/gradient"
import * as Form from "@radix-ui/react-form"
import "mapbox-gl/dist/mapbox-gl.css"
import React from "react"

export const SendButton = React.forwardRef<HTMLButtonElement, React.ComponentProps<"button">>((props, ref) => {
    return (
        <Button
            {...props}
            variant="tertiary"
            className="text-basic-content-light"
            data-testid="chat-send-button"
            ref={ref}
        >
            <i className="ri-send-plane-2-fill text-[1.5rem]"></i>
        </Button>
    )
})

type ChatInputBaseProps = React.PropsWithChildren<{
    header?: React.ReactNode
    tools?: React.ReactNode
    mainInput: React.ReactNode
    sendButton: React.ReactNode
}>

export function ChatInputBase(props: ChatInputBaseProps) {
    const { sendButton, header, tools, mainInput, children } = props

    return (
        <GradientBorder variant="light" radius="big">
            <Form.Field name="chat" className="overflow-hidden rounded-3xl bg-basic-strong">
                <div className="col-span-3 overflow-hidden">{header}</div>
                <div className="grid grid-cols-auto-1fr-auto items-center py-1">
                    <div className="flex gap-4 px-4 ">{tools}</div>

                    <Form.Control
                        asChild
                        className="text-base-content resize-none overflow-hidden bg-transparent text-body2 focus-within:outline-none
                            focus-visible:outline-none"
                    >
                        {mainInput}
                    </Form.Control>

                    {children}

                    {sendButton}
                </div>
            </Form.Field>
        </GradientBorder>
    )
}
