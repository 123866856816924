import { useGDaten } from "@api/g-daten/useGDaten"
import { useProjects } from "@api/projects/useProjects"
import { useProjectIdParam } from "@hooks/useProjectIdParam"
import * as Select from "@radix-ui/react-select"
import { useNavigate } from "react-router"

export function ChatProjectSelect() {
    const projectId = useProjectIdParam()
    const { data: projects } = useProjects()
    const { data: gDaten } = useGDaten(projectId)
    const navigate = useNavigate()

    const onChange = (value: string) => {
        navigate(`/authenticated/chat/${value}`)
    }

    const liegenschaftName = gDaten?.Gebaeudeadresse_Strasse_Nr ?? `Liegenschaft ${projectId}`
    return (
        <div>
            <p className="text-bodyStrong">Kontext</p>
            <Select.Root value={projectId?.toString()} onValueChange={onChange}>
                <Select.Trigger className="relative flex items-center justify-items-start gap-1 py-2 text-bodyRegular">
                    <p>Liegenschaften {">"}</p>
                    {liegenschaftName}
                </Select.Trigger>
                <Select.Portal>
                    <Select.Content className="absolute z-50" position="popper" align="start">
                        <Select.Viewport className="max-h-96 w-[max-content] rounded-lg bg-basic-accent p-4">
                            {projects?.map((project) => (
                                <Select.Item
                                    key={project.id}
                                    value={project.id.toString()}
                                    className="cursor-pointer rounded-lg px-4 py-2 hover:scale-[1.02]"
                                >
                                    <Select.ItemText>{project.name}</Select.ItemText>
                                </Select.Item>
                            ))}
                        </Select.Viewport>
                    </Select.Content>
                </Select.Portal>
            </Select.Root>
        </div>
    )
}
