import { apiUrl, handleFetch } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useAppState } from "@hooks/useState"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "react-router"
import { toast } from "sonner"

export function useRemoveUser(userId: number | null) {
    const authHeader = useAuthHeader()
    const clearAuth = useAppState((state) => state.clearAuth)
    const setIsLoading = useAppState(state => state.setIsLoading)
    const navigate = useNavigate()

    return useMutation({
        mutationFn: async () => {
            if (!authHeader || !userId) {
                return null
            }

            setIsLoading(true)

            return handleFetch(() =>
                fetch(`${apiUrl}/users/${userId}`, {
                    method: "delete",
                    headers: {
                        ...authHeader,
                    },
                })
            )
        },
        onError: () => {
            setIsLoading(false)
        },
        onSuccess: async () => {
            setIsLoading(false)
            clearAuth()
            navigate("/auth/login-selection")
            toast.success("Account gelöscht")
        },
    })
}
